.startAdornment {
  width: 25px;
  height: 25px;
  background-color: #f59271;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.option {
  display: flex;
  align-items: center;
  gap: 4px !important;
}
