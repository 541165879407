html {
    scroll-behavior: smooth;
}

.infos_header {
    display: inline-block;
    width: 100%;

    .headerTitle {
        text-align: center;
        
        /* max-width: 586px; */
        padding: 10px 0;
        /* display: flex; */
        flex-direction: column;
        gap: 20px;

        @media (max-width: 1080px) {
            text-align: center;
            max-width: 750px;
            margin: 0 auto;
        }
    }
}

.logoImg {
    /* width: 302px; */

    @media (max-width: 396px) {
        width: 222px;
    }
}

h1 .responseTitle {
    line-height: 70px !important;

    @media (max-width: 480px) {
        font-size: 40px;
        line-height: 48px !important;
    }
}

h2 .responseTitle {
    line-height: 48px !important;

    @media (max-width: 480px) {
        font-size: 36px;
        line-height: 48px !important;
    }
}