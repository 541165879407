.headerContainer {
  background-color: #f16434;
  padding: 12px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f59271;
}
.headerLogoSection {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.navigationIcon {
  color: black;
  font-size: 26px;
  cursor: pointer;
}
.disabled {
  color: rgb(190, 188, 188);
  cursor: default !important;
  font-size: 26px;
}
.reportsList {
  min-width: 350px;
  height: 50px;
}
@media only screen and (max-width: 1500px) {
  .reportsList {
    min-width: 150px;
    flex-wrap: nowrap;
  }
}
