.keyFeatures {
  text-align: center;
  font-size: 56px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  letter-spacing: -1.12px;
}

.keyDetial {
  margin-top: 20px;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  /* 32px */
}