.crossButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.closeButton {
  cursor: pointer;
  color: black;
  height: 20px;
  width: 20px;
}

.modalContainer {
  margin: 40px 122px;
}

.header {
  display: flex;
  justify-content: space-between;
}

.heading {
  display: flex;
  gap: 16px;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.pageName {
  color: #202020;
  margin-top: 10px;
  font-size: 18px;
}

.pageName:hover {
  color: #f16434;
}


.shareContainer {
  display: flex;
  justify-content: space-between;
}

.shareContainer2 {
  display: flex;
  gap: 6px;
  cursor: pointer;
  align-items: center;
}

.shareButton {
  color: var(--Primary-NP-Digital-Orange, #ff5e29);
  font-size: 14px;
  padding-left: 8px;
  font-style: normal;
  font-weight: 400;
  text-decoration-line: underline;
}

.headerButtonContainer {
  display: flex;
  gap: 32px;
}

.saveButton {
  display: flex;
  height: 48px;
  padding: 13px 70px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--White-White, #fff);
  text-align: center;
  background: #f16434;
  border-radius: 2px;
  border: 1px solid #f16434;
  /* Headings (Caps)/Button */
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.12px;
  text-transform: uppercase;
  cursor: pointer;
}

.follow_button {

  background-color: #F16434;
  color: #FFFFFF;
  font-weight: 700;
  font-size: 16px;
  text-decoration: capitalize;
}