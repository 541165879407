@import '@styles/_variables';

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 16px;
}

.footerContainer {
  padding: 32px 0;
}
.npDigital {
  text-decoration: none;
  color: black;
}
.npDigital:hover {
  color: #f16434;
}

.footerTitleContainer {
  margin-bottom: 16px;
}

.footerImageContainer {
  display: flex;
  // justify-content: center;
  // align-items: center;
  height: 100%;
  width: 100%;

  & > img {
    margin-left: -20px;
    max-width: 100%;
  }
}

.footerDesc {
  margin-right: 24px;
}

.subFooter {
  display: flex;
  flex-direction: column;
}

.policiesRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  & a {
    margin-right: 12px;
  }
}
.copyright {
  margin-top: 1rem;
}

// @media (max-width: 768px) {
//   .footerImageContainer {
//     justify-content: flex-start;

//     & > img {
//       margin-left: -20px;
//     }
//   }
// }
