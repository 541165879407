.crossButton {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
  .closeButton {
    cursor: pointer;
    color: black;
    height: 20px;
    width: 20px;
  }