.boardThumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.boardThumbnails>div {
  width: calc(33.33% - 10px);
}

.extraImages {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  height: 40px;
  width: 40px;
  background: #fff;
  color: #3c3b41;
  border-radius: 25px;
  text-align: center;
  padding-top: 4px;
}

.boardsCounter {
  font-weight: bold;
  height: 20px;
  display: flex;
  padding: 6px;
  font-size: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--Secondary-Light-Gray, #d1d7d9);
  background: var(--secondary-tints-light-gray-30, #f1f3f4);
}

.counterContainer {
  margin-bottom: 7px;
  height: 26px;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--Secondary-Light-Gray, #D1D7D9);
  background: var(--secondary-tints-light-gray-30, #F1F3F4);
}

.counterContainer1 {
  /* margin-bottom: 4px; */
  height: 20px;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--Secondary-Light-Gray, #D1D7D9);
  background: var(--secondary-tints-light-gray-30, #F1F3F4);
}

.shareButton {
  background-color: #f16434;
  cursor: pointer;
  /* border-color: #f16434; */
  border-color: white;
  font-weight: bold;
  /* color: white; */
  width: 140px;
  height: 48px;
  letter-spacing: 1.12px;
  border: none;
}

.searchButton:disabled {
  /* filter: blur(2px); */
  background-color: #939395;
  border: 1px solid #939395;
  cursor: not-allowed;
  /* Optional: changes cursor to not-allowed */
  opacity: 0.5;
  /* Optional: reduces opacity to indicate disabled state */
}

.searchButton {
  min-width: 140px;
  border: 1px solid #f16434;
  border-radius: 2px;
  background: #f16434;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.12px;
  height: 48px;
  cursor: not-allowed;
}

/* .shareButton:hover {
  background-color: #fff;
  color: #f16434;
  border: 1px solid #f16434;
} */

.clearTag {
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-left: 20px;
  text-decoration-line: underline;
}

.clearTag:hover {
  color: #f16434;
  font-size: 17px;
}

.tabTitle {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 300;
}