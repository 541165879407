.container {
  background: white;
  display: flex;
  align-items: center;
}

.CheckboxCustom {
  /* font-family: 'Geomanist'; */
  font-size: 18px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: #939395;
}

.image {
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  align-self: flex-start;
  background-color: white;
  box-shadow: 0px 3px 32px 0px #a1a1a133;
  background-color: #ffffff;
}

.textDiv {
  background: white;
  box-shadow: 0px 10px 32px 0px #20202014;
  min-width: 639px;
  padding: 56px;
}
.quoteContent {
  box-shadow: 0px 10px 32px 0px #20202014;
  background-color: white;
  padding: 16px 24px 16px 24px;
  /* font-family: 'Geomanist'; */
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}
