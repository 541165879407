.learningTop {
    color: var(--secondary-tints-onyx-50, #9D9DA0);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;

    text-decoration-line: underline;
}

.learningTop:hover {
    color: #f16434;
}

.catagory {
    color: var(--Primary-Dark-Gray, #202020);
    font-style: normal;
    font-size: 14px;
    font-weight: 400;
    line-height: 100%;

}

.slash {
    color: var(--Secondary-Gray, #A3B0B3);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    margin-left: 12px;
    margin-right: 12px;
    /* 14px */
}