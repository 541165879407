.divider {
  display: block;
  height: 1px;
  width: 100%;
  background-color: #D1D7D9;
  border-bottom: 1px solid #E8EBEC;
}

.sliderMargin {
  /* margin-left: 6px; */
  margin-left: 0px;
  margin-top: 0px;
}

.customPannel.ant-collapse-content {
  padding: 16px;
  background-color: aqua;
}

/* card slider range */
.whiteBox {
  /* padding: 4px; */
  background-color: white;
  border-width: 1px 1px 4px 1px;
  border-style: solid;
  border-color: #E8EBEC;
}

.cardHeading {
  padding-bottom: 20px;
  padding-top: 8px;
  /* font-family: Geomanist; */
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #26282C;
}

.cardHeadingcheckBox {
  /* padding-bottom: 20px;
  padding-top: 8px; */
  /* font-family: Geomanist; */
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #26282C;
  display: flex;
  align-items: center;
}

.cardValue {
  margin-top: 17px;
  margin-left: 17px;
  align-items: center;
  display: flex;
}

.CardCurrency {
  color: #D1D7D9;
  margin-right: 10px;
  /* font-family: Geomanist; */
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.checkboxCustom {
  /* font-family: Geomanist; */
  font-size: 12px;
  font-weight: 400;
  line-height: 12px;
  letter-spacing: 0em;
  text-align: left;
  color: #939395;
}

.greyCardCurrency {
  margin-right: 6px;
  /* font-family: Geomanist; */
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  color: #202020;
}

.cardAmount {
  /* font-family: Geomanist; */
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

/* grey card */
.greyBox {
  overflow: hidden;
  padding: 20px;
  padding-left: 0px;
  background-color: #EBEEF0;
  border-width: 1px;
  border-style: solid;
  /* border-color: #E8EBEC; */
  border: 1px solid #D1D7D9;
}

.greyCardValue {
  margin-left: 15px;
  display: flex;
}

/* dollarPercentage */
.ActiveDollarPercentage {
  border: 2px solid #FF5E29;
  padding: 20px;
  margin-top: 40px;
  cursor: pointer;
  background-color: white;
}

.dollarPercentage {
  background-color: white;
  border: 1px solid #E8EBEC;
  padding: 20px;
  margin-top: 40px;
  cursor: pointer;
}

/*  card right col */
.pinkCardValue {
  /* margin-top: 17px;
  margin-left: 7px; */
  align-items: center;
  display: flex;
}

.pinkCard {
  border: 1px solid #E53435;
  background-color: #fe84851a;
  height: 120px;
  padding: 20px;
  max-height: 200px;
}

.CardCurrencyPink {
  /* color: #D1D7D9; */
  margin-right: 10px;
  margin-left: -7px;
  /* font-family: Geomanist; */
  font-size: 24px;
  /* font-weight: 500; */
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
}

.cardPinkcontent {
  /* margin: 15px; */
  /* font-family: Geomanist; */
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.cardPinkmargin {
  margin-bottom: 20px;
  margin-top: 20px;
}

.greenCard {
  border: 1px solid #00A862;
  background-color: #ecfef480;
  padding: 20px;
  height: 120px;
  max-height: 200px;
}

.lightPurpleCard {
  border: 1px solid #A0C2F9;
  background-color: #ecf3fe80;
  padding: 20px;
  height: 120px;
  max-height: 200px;
}

.rightColCard {
  box-shadow: 0px 3px 32px 0px #a1a1a133;
  background-color: #FFFFFF;
  padding: 25px;
  /* margin-right: 51px; */
}

.leftColCard {
  background-color: #FFFFFF;
  /* padding: 25px; */
}

.headingIcon {
  margin-right: 10px;
  width: 32px;
  height: auto;
  border-radius: 50%;
  /* padding: 6px; */
  text-align: center;
  height: 32px;
  background-color: #FFDFD4;
}

.headingIconGrey {
  width: 24px;
  border-radius: 50%;
  text-align: center;
  height: 24px;
  background-color: #E9E9EA;
  padding: 1px;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
  /* Firefox */
}