.addButton {
    color: white;
    background-color: #f16434 !important;
    font-size: 28px;
    text-align: center;
    width: 36px;
    cursor: pointer;
    border: 1px solid #f16434 !important;
    border-radius: 0.1rem
}

.addButton:disabled {
    color: white;
    background-color: #a3b0b3;
    border: 1px solid #a3b0b3 !important;
    cursor: not-allowed
}