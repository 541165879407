@import '@styles/_variables.scss';

.containerInput {
  display: flex;
  position: relative;

  @media (max-width: 678px) {
    flex-direction: column;
    gap: 6px;
  }
}

.inputEmail {
  display: inline;
  background-color: #fbfcfd;
  color: #808080;
  padding: 14px 16px;
  height: 60px;
  font-size: 14px;
  border: none;
  border-radius: 3px 0 0 3px;
  width: 100%;
  max-width: 100%;

  @media (max-width: 678px) {
    border-radius: 3px;
  }

  &:focus {
    outline: none;
    border: 1px solid #e8ebed;
  }

  &::placeholder {
    color: #4e4e4e;
  }
}

.isNot {
  z-index: 1;
  outline: 1px solid red;
  border: 1px solid red;
}

.proceedButton {
  display: inline;
  background-color: #f16434;
  width: auto;
  min-height: 60px;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  border-radius: 0px 3px 3px 0px;
  border: none !important;
  padding: 12px 40px !important;
  font-weight: bold;
  font-size: 14px;

  @media (max-width: 678px) {
    border-radius: 3px;
    min-width: auto;
  }

  &:hover {
    color: #fff;
    background-color: #ec8360;
    cursor: pointer;
  }

  &:focus {
    color: #fff;
    background-color: #ec8360;
    cursor: pointer;
  }
}
.error {
  color: red;
}
.isFooter {
  color: red;
  text-align: left;
}
.load {
  position: absolute;
  right: 5%;
  bottom: -32px;
  display: block;
  width: 28px;
  height: 28px;
  background-color: #23282d;
  opacity: 0.75;
  border-radius: 99px;
  padding: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bar {
  display: block;
  width: 100%;
  animation-duration: 1s;
  animation-name: loader;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ball {
  display: block;
  width: 6px;
  height: 6px;
  background-color: #fff;
  opacity: 0.75;
  border-radius: 99px;
}

@keyframes loader {
  0% {
    transform: rotateZ(-360deg);
  }
}
