@import '@styles/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.row {
  width: 100%;
}

.loginHeader {
  text-align: center;
  background-color: $primary-color;
  padding: 64px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}

.logo {
  margin-bottom: 48px;
}

.googleIcon {
  margin-right: 12px;
  margin-bottom: -2px;
}

.title {
  margin: 24px 0;
  width: 100%;
  max-width: 650px;
}

.loginCard {
  margin-bottom: -80px;
}

.tryAgainButton {
  width: 100%;
  max-width: 280px;
  margin-top: 24px;
}

.cardAlert {
  padding: 32px;
  display: flex;
  gap: 32px;
  align-items: center;
  justify-content: center;
  margin-bottom: 150px ;

  /*   border: 1px solid #ffc107;
  background-color: #ffc1073f; */
}
