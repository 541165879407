.keyFeatures {
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.12px;
}

.keyDetial {
    margin-top: 20px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    /* 32px */
}

.heading {
    margin-top: 20px;
    text-align: center;
}

.detail {
    margin-top: 12px;
    width: 340px;
    text-align: center;

}

.lines {
    width: 172px;
    letter-spacing: 6px;
    margin-top: 222px;
    color: #E9E9EA;
}