.cardHeaderSetting {
    display: flex;
    justify-content: space-between;
    /* border: 1px solid #dfe3e4; */
}

.childCollapse {
    /* width: 302px; */
    min-height: 86px;
    border-right: var(--counter, 1px) solid var(--secondary-tints-light-gray-70, #dfe3e4);
}

.childCollapse1 {
    /* width: 255px; */
    min-height: 86px;
    border-right: var(--counter, 1px) solid var(--secondary-tints-light-gray-70, #dfe3e4);
}

.childCollapse2 {
    /* width: 255px; */
    min-height: 86px;
}

.childCollapse3 {
    /* width: 188px; */
    min-height: 77px;
    border-right: var(--counter, 1px) solid var(--secondary-tints-light-gray-70, #dfe3e4);
    /* margin-bottom: 20px; */
}

.childCollapse4 {
    width: 188px;
    height: 77px;
    /* border-right: var(--counter, 1px) solid var(--secondary-tints-light-gray-70, #DFE3E4); */
}

.divContent {
    font-style: normal;
    /* line-height: 16px; */
}

.headingIcon {
    width: 24px;
    border-radius: 50%;
    text-align: center;
    height: 24px;
    padding: 1px;
}

.tableHeader {
    display: flex;
    gap: 6px;
}

.tableTitle {
    display: flex;
    gap: 6px;
    margin-top: 21px;
    margin-left: 20px;
}

.tableTag1 {
    margin-top: 7px;
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 7px 8px;
    justify-content: center;
    border-radius: 4px;
    background: #ffdede;
}

.tableImg {
    text-align: center;
    margin-top: 3px;
}

.tableLeft {
    display: flex;
    flex-direction: column;
}

.tableLeftContainer {
    min-height: 86px;
    border-right: 1px solid #e8ebec;
    border-bottom: 1px solid #e8ebec;
}

.tableLeftContainer2 {
    display: flex;
    margin-top: 21px;
    margin-left: 20px;
}

.tableLeftContainer3 {
    margin-top: 18px;
    margin-left: 20px;
    margin-bottom: 24px;
    display: flex;
    gap: 6px;
}

.tableTag2 {
    margin-top: 2px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    width: 43px;
    /* padding: 6px; */
    justify-content: center;
    border-radius: 4px;
    background: var(--graphs-charts-light-green, #E0FAEF);
}

.tableTagGood {
    margin-top: 2px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    width: 43px;
    /* padding: 6px; */
    justify-content: center;
    border-radius: 4px;
    background: var(--graphs-charts-light-green, #E0FAEF);
}

.tableTagBad {
    margin-top: 2px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    width: 33px;
    /* padding: 6px; */
    justify-content: center;
    border-radius: 4px;
    background: #FFDEDE;
}

.tableTagMedium {
    margin-top: 2px;
    display: inline-flex;
    align-items: center;
    height: 20px;
    width: 57px;
    /* padding: 6px; */
    justify-content: center;
    border-radius: 4px;
    background: #FFEFD4;
}