@import '@styles/_variables.scss';

.container {
  background: white;
  height: 455px;
  width: 406px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.lowContainer {
  background: white;
  height: 405px;
  width: 406px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
}

.titleDiv {
  position: block;
  margin-top: 50px;
  text-align: center;
}

.percentageDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -70px;
}
.percentageDiv span {
  font-size: 80px;
  font-family: $font-title;
}

.progressBar {
  height: 14px;
  width: 270px;
  background: #f1f3f4;
  border-radius: 16px;
  margin-top: -30px;
}

.percentage1 {
  color: #fe8485;
}
.percentage2 {
  color: #f4e26c;
}
.percentage3 {
  color: #85ecc1;
}
.progress-percentage1 {
  background: #fe8485;
}
.progress-percentage2 {
  background: #f4e26c;
}
.progress-percentage3 {
  background: #85ecc1;
}

.footer {
  background: #202020;
  height: 136px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.range {
  background: #fff;
  height: 86px;
  width: 100%;
  display: flex;
  align-items: end;

  & > div {
    width: 100%;
    background-color: #202020;
    height: 90px;
  }
}
.consultantButton {
  margin-bottom: 10px;
}
