@import '@styles/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.row {
  width: 100%;
}

.loginHeader {
  text-align: center;
  background-color: $primary-color;
  padding: 64px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}

.logo {
  width: 302px;
}

.googleIcon {
  margin-right: 12px;
  margin-bottom: -2px;
}

.title {
  width: 100%;
  margin: 48px 0;
  text-align: center;
}

.loginCard {
  margin-bottom: -80px;
  text-align: center;
}

.fakeAntInput,
.dialCodeInput {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 10.4px 11px;
  color: #000;
  font-size: 16px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 100%;
  outline: 0;
  height: 48px;

  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #ff8e61;
  }

  &[aria-invalid='true'] {
    border-color: red;
  }
}

.phoneField {
  border: 1px solid #d9d9d9;
  width: 100%;
  font-size: 16px;
  line-height: 1.5715;
  background-color: #fff;
  outline: 0;
  height: 48px;

  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #ff8e61;
  }

  & > div {
    padding-right: 4px;
    border-right: 1px solid #d9d9d9;
  }

  & div[aria-hidden='true'] {
    margin-left: 8px;
  }

  & input[type='tel'] {
    border: 0;
    width: 100%;
    height: 100%;
    outline: none;
  }
}

.errorText {
  display: inline-block;
  font-size: 12px;
  line-height: 12px;
  color: red;
}

.phoneRow {
  display: flex;
  position: relative;
}

.dialCodeInput {
  width: 44px;
  padding: 4px;
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin-right: -1px;
  position: absolute;
  z-index: 99;
}
