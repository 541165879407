.registerHere {
    color: #f16434;
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
}

/* .registerHere:hover {
    color: #f16434;
    text-decoration: underline;
    cursor: pointer;
    font-size: 13px;
} */

.errorMessage {
    color: red;
    font-size: 12px;
    margin-top: 4px;
}

.errorInput {
    border-color: red !important;
}

.inputHeader {
    font-weight: bold;
    font-size: 16px;
}

.errorMessage {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    text-align: left;
    /* Reduce the top margin */
}

.noMargin {
    margin-bottom: 8px;
    text-align: left;
    /* Reduce the bottom margin */
}



.privacyPolicy {
    text-decoration: underline;
}

/* .privacyPolicy:hover {
    font-size: 13px;
    text-decoration: underline;
} */

.requiredAsterisk {
    color: #f16434;
}

.searchButton {
    border: 1px solid #f16434;
    /* width: -webkit-fill-available;
    width: -moz-available; */
    border-radius: 2px;
    background: #f16434;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    height: 48px;
    cursor: pointer;
}

.searchButton:hover {
    background-color: #ffffff;
    color: #f16434;
    border: 1px solid #f16434;
}

.searchButton:disabled {
    cursor: not-allowed;
    /* Indicate it's not clickable */
    opacity: 0.6;
    background-color: #B3B3B3;
    color: #ffffff;
    border: 1px solid #B3B3B3;
    /* Dim the button to indicate disabled state */
}

/* Prevent hover effect when the button is disabled */
.searchButton:disabled:hover {
    background-color: #B3B3B3;
    color: #ffffff;
    border: 1px solid #B3B3B3;
}