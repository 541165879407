.card {
  margin-top: 32px;
  /* min-height: 628px; */
  cursor: pointer;
}

.profileContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  align-items: center;
}

.profile {
  display: flex;
  gap: 10px;
  align-items: center;
}

.profileIcons {
  /* margin-top: 5px; */
  height: 16px;
  width: 16px;
}

.cardImageContainer {
  margin-top: 24px;

  /* height: 372px; */
  /* background: #f6f7f7; */
  text-align: center;
  width: -moz-available;
}

.cardImage {
  /* margin-top: 45px; */
  /* min-height: 628px; */
  object-fit: contain;
  width: -webkit-fill-available;
  width: -moz-available;
}

.pinterestIcon {
  margin-top: 5px;
  height: 12px;
  width: 9.4px;
}

.signupContainer {
  background: #ecf3fe;
  min-height: 84px;
  display: flex;
  justify-content: space-between;
}

.signupTextContainer {
  margin-top: 16px;
  margin-left: 16px;
}

.signupButtonContainer {
  margin-top: 30px;
  margin-right: 30px;
}

.cardButton {
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #3c3b41;
  background: var(--Secondary-Onyx, #3c3b41);
  color: var(--Primary-White, #fff);
  font-size: 12px;
  font-style: 500;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  cursor: pointer;
}

.shareContainer {
  display: flex;
  justify-content: space-between;
}

.shareTextContainer {
  display: flex;
  gap: 6px;
  cursor: pointer;
}

.buttonContainer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.iconButtonContainer {
  display: flex;
  /* gap: 8px; */
}

.saveButton {
  display: flex;
  cursor: pointer;
  /* padding: 0px 51px 0px 51px; */
  /* width: 170px; */
  width: -webkit-fill-available;
  height: 32px;
  align-items: center;
  background: #fff;
  border: 1px solid #ebebec;
  justify-content: space-between;
  margin-bottom: 10px;
}

.addButton {
  cursor: pointer;
  background: none !important;
  border: none;
  width: 40px;
  align-items: center;
  justify-content: center;
  position: relative;
  /* Ensures consistent alignment */
}

.addButton:hover {
  /* border: 1px solid #f16434;
   border: none; */
  /* font-size: 30px; */
}

.moreOptionsButton {
  /* border: none; */
}

.moreOptionsButton:hover {
  background: none;
}

.closeButton {
  cursor: pointer;
  color: black;
  height: 20px;
  width: 20px;
}

.addIcon {
  display: block;
}

.hoveredIcon {
  display: none;
}

.addButton:hover .addIcon {
  display: none;
}

.addButton:hover .hoveredIcon {
  display: block;
}

.copyIcon,
.copyIconOrange {
  width: 32px;
  display: block;
}

.copyIconOrange {
  display: none;
  /* Hide the hover icon initially */
}

.addButton:hover .copyIcon {
  display: none;
}

.addButton:hover .copyIconOrange {
  display: block;
}

.saveButton:hover {
  border: 1px solid #f16434;
  color: #f16434 !important
}

.saveButton:hover .addToBoard {
  color: #f16434;
}

.saveButton:hover .copyIconOrange {
  display: block;
}

.saveButton:hover .copyIcon {
  display: none;
}

.saveButton .copyIcon {
  display: block;
}

.saveButton .copyIconOrange {
  display: none;
}


.card {
  position: relative;
  overflow: hidden;
}

.ribbon {
  position: absolute;
  top: 10px;
  left: -30px;
  width: 90px;
  /* Adjust width for a smaller ribbon */
  padding: 0px 0;
  /* Reduce padding for a shorter height */
  background-color: #f16434;
  color: white;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  transform: rotate(-45deg);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  animation: shimmer 3s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -100px;
  }

  50% {
    background-position: 200px;
  }

  100% {
    background-position: -100px;
  }
}

.ribbon::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  top: 0;
  left: 0;
  opacity: 0.8;
  animation: shimmer 2.5s infinite;
}

.dateText {
  font-size: 14px;
}

@media (max-width: 1500px) {
  .dateText {
    font-size: 12px;
  }
}

.footerIcon {
  width: 32px;
}

.addToBoard {
  color: #939395;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}

.addToBoard:hover {
  color: #f16434;
}

@media (max-width: 1700px) {

  .copyIcon,
  .copyIconOrange {
    width: 20px;
    display: block;
  }

  .copyIconOrange {
    display: none;
    /* Hide the hover icon initially */
  }

  .footerIcon {
    width: 24px;
  }

  .addToBoard {
    font-size: 8px;
  }

  .saveButton {
    margin-top: 1px;
    height: 21px;
  }
}

.followButton {

  cursor: pointer;
  /* padding: 0px 51px 0px 51px; */
  /* width: 170px; */
  /* width: -webkit-fill-available; */
  height: 32px;
  align-items: center;
  background: #fff;
  border: 1px solid #ebebec;
  justify-content: space-between;
  margin-bottom: 10px;
}

.followButton:hover {
  border: 1px solid #f16434;
  color: #f16434 !important
}

.followButton:hover .addToBoard {
  color: #f16434;
}