.reportHeader {
  font-weight: 700;
}
.active {
  color: #f16434;
}
.sectionName {
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}
.sectionName:disabled {
  color: gray;
  cursor: default !important;
}
.sectionName:hover {
  font-weight: 700;
  color: black !important;
}
.reportsAccordion {
  display: flex;
  flex-direction: column;
  padding: 1rem 0 0 0.7rem;
  gap: 1rem;
  overflow: auto;
  max-height: calc(100vh - 230px);
  height: calc(100vh - 230px);
  width: 100%;

  .reportButton {
    cursor: pointer;
    border: none;
    background-color: transparent;
    text-align: start;
  }

  .reportButton:hover {
    font-weight: 700;
  }
}
.pannelHeader {
  font-weight: 700;
}
