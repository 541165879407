$font-title: 'geomanist-bold', sans-serif;
$font-text: 'geomanist-regular', sans-serif;

// Sizes
$sizes: 12, 14, 16, 18, 20, 24, 28, 32, 40, 48, 64, 80, 90, 100, 150;

$primary-background-color: #f16434;
$primary-color: #f16434;
$secondary-color: #919191;
$error: rgb(243, 93, 93);
$black-color: #202020;
$white-color: #fff;
$gray1: #f1f3f4;
