.forgotPasswordContainer {
  width: 100%;
  overflow: hidden;
  height: calc(100vh - 87px)
}
.buttonSmall {
  padding: 0 2rem !important;
}
.modal {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.modalHeading {
  font-weight: 600;
}
.description {
  margin-top: 3rem;
  max-width: 400px;
  text-align: center;
}
.divider {
  width: 100px;
  border-bottom: 3px solid tomato;
}
