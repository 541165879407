.container {
  width: 100%;
  height: calc(100vh - 86px);
  display: flex;
  overflow: hidden;

  .sidebar {
    width: 100%;
    max-width: 273px;
    height: 100%;
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-right: 1px solid #e8ebec;
    overflow-y: scroll;

    .active {
      color: #f16434;
    }

    .bold {
      font-weight: 700;
    }

    .sidebarTop {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .reportHeader {
        font-weight: 700;
      }

      .reportsAccordion {
        display: flex;
        flex-direction: column;
        padding: 1rem 0 0 0.5rem;
        gap: 1rem;
        overflow: auto;
        max-height: calc(100vh - 230px);
        height: calc(100vh - 230px);
        width: 100%;

        .reportButton {
          cursor: pointer;
          border: none;
          background-color: transparent;
          text-align: start;
        }

        .reportButton:hover {
          font-weight: 700;
        }
      }

      .sidebarButton {
        width: 100%;
        border: none;
        cursor: pointer;
        text-align: left;
        background: #f6f7f7;
        height: 40px;
        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        padding-left: 15px;
      }
    }
  }
  .sidebar::-webkit-scrollbar {
    width: 4px;
  }
  .sidebar::-webkit-scrollbar-thumb {
    width: 8px;
    background: rgb(163, 176, 179);
  }
  .sidebar::-webkit-scrollbar-track {
    background: rgb(209, 215, 217);
  }
  .belowReportsAccordion {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 0.5rem;
    position: absolute;
    bottom: 0%;
    max-width: 273px;
    gap: 1rem;
    overflow: auto;
    width: 100%;
    background: #f6f7f7;
    border-top: 1px solid #d1d7d9;
    .textWithLogo {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 1px 6px;
    }
    .belowReportButton {
      cursor: pointer;
      border: none;
      background-color: transparent;
      text-align: start;
      color: black;
    }
    .belowReportButton:hover {
      font-weight: bolder;
    }
  }

  .belowReportsAccordion2 {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 1rem 0.5rem;
    position: absolute;
    bottom: 7%;
    max-width: 273px;
    gap: 1rem;
    overflow: auto;
    width: 100%;
    background: #f6f7f7;
    border-top: 1px solid #d1d7d9;
    .textWithLogo {
      display: flex;
      align-items: center;
      gap: 5px;
      padding: 1px 6px;
    }
    .belowReportButton {
      cursor: pointer;
      border: none;
      background-color: transparent;
      text-align: start;
      color: black;
    }
    .belowReportButton:hover {
      font-weight: bolder;
    }
  }

  .reportContainer {
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .selectReport {
    //   padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .selectReportHeading {
    text-align: center;
  }
}
.loadingContainer {
  width: 100%;
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sectionName {
  border: none;
  background-color: transparent;
  text-align: left;
  cursor: pointer;
}
.sectionName:disabled {
  color: gray;
  cursor: default !important;
}
.sectionName:hover {
  font-weight: 700;
  color: black !important;
}
.active {
  font-weight: 700;
  color: black !important;
}
