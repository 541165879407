@import '@styles/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.row {
  width: 100%;
}

.policiesHeader {
  text-align: center;
  background-color: $primary-color;
  padding: 64px 16px 24px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 48px;
}

.logo {
  width: 302px;
}

.title {
  width: 100%;
  max-width: 650px;
  margin-top: 48px;
  margin-bottom: 8px;
}

.termsCard {
  margin-bottom: -80px;
  text-align: center;
}

.article {
  p,
  a,
  li {
    font-size: 20px;

    @media screen and (max-width: 768px) {
      font-size: 16px;
    }
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: $font-title;
  }

  h2 {
    font-size: 24px;
    line-height: 1.25em;
    margin-bottom: 1.25em;
    padding-top: 1.666666666667em;
    font-weight: 600;
  }

  ol,
  ul {
    padding: 0;
    margin-left: 32px;
  }

  li {
    margin-bottom: 16px;
  }

  ul li {
    list-style: none;
    position: relative;
    padding: 0 0 0 33px;
  }

  ul li::before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    background: $primary-color;
    position: absolute;
    top: 0.8333333333335em;
    left: 0;
    margin-top: -4px;
  }
}
