.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #f16434;
  margin: 0;
}

.inputContainer {
  display: flex;
  gap: 24px;
}

.searchInput {
  width: 400px;
}

.createBoardBtn {
  background-color: #f16434;
  border-color: #f16434;
  font-weight: bold;
  font-size: 14px;
}

.createBoardBtn:hover {
  background-color: #fff;
  color: #f16434;
  border: 1px solid #f16434;
}

.counterContainer {
  /* margin-bottom: 7px; */
  height: 26px;
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  border: 1px solid var(--Secondary-Light-Gray, #D1D7D9);
  background: var(--secondary-tints-light-gray-30, #F1F3F4);
}