.heading {
  color: var(--Primary-Orange, #f16434);
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 118.182%;
  /*  */
}


.clearFilter {
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: underline;
}

.clearFilter:hover {
  color: #f16434;
  font-size: 17px;
}