@import '@styles/_variables.scss';

.containerDiv {
  border: 1px solid #d1d7d9;
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
}

.titleDiv {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  & > div {
    text-align: center;
  }
}

.tooltipText {
  margin-left: 8px;
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a3b0b3;
}

.textDiv {
  max-width: 250px;
  text-align: center;
  margin-bottom: 16px;
}

.consultantButton {
  height: auto;
  white-space: normal;
}
