@import '@styles/_variables.scss';

.containerDiv {
  border: 1px solid #d1d7d9;
  height: 100%;
  width: 100%;
  padding: 24px;
  line-height: 1.6;
}

.titleDiv {
  display: flex;
  align-items: center;
}

.tooltipText {
  margin-left: 8px;
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a3b0b3;
}

.valueDiv {
  display: flex;
  align-items: center;
}

.tag {
  margin-left: 8px;
}
