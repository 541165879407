.searchBar {
    background-color: white;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding-left: 48px;
    padding-right: 48px;
    padding-top: 26px;
    padding-bottom: 26px;

}

.search-tabs .ant-tabs-nav {
    margin-bottom: 16px;
}

.search-controls {
    margin-top: 16px;
}

.filterButtonContainer {
    display: flex;
    gap: 4px;
    cursor: pointer;
}

.filterButton {
    font-weight: bold;
    background: none;
    font-size: 14px;
    border: none;
    margin-top: -18px;
    cursor: pointer;
}

.searchButton {
    border: 1px solid #f16434;
    width: -webkit-fill-available;
    width: -moz-available;
    border-radius: 2px;
    background: #f16434;
    color: #ffffff;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.12px;
    height: 48px;
    cursor: pointer;
}

.searchButton:hover {
    background-color: #ffffff;
    color: #f16434;
    border: 1px solid #f16434;
}

.searchButton:disabled {
    cursor: not-allowed;
    /* Indicate it's not clickable */
    opacity: 0.6;
    background-color: #B3B3B3;
    color: #ffffff;
    border: 1px solid #B3B3B3;
    /* Dim the button to indicate disabled state */
}

/* Prevent hover effect when the button is disabled */
.searchButton:disabled:hover {
    background-color: #B3B3B3;
    color: #ffffff;
    border: 1px solid #B3B3B3;
}

.inputText {
    font-size: 14px;
    color: #939395;
    /* border: 1px solid #D1D7D9; */
}

.tabTitle {
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 300;
    margin-top: 2px;
}

.checkbox {
    font-size: 14px;
    /* line-height: 16px; */
    margin-left: 12px;
    font-weight: bold;
}