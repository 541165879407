.crossButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.closeButton {
  cursor: pointer;
  color: black;
  height: 20px;
  width: 20px;
}

.invitedMembers {
  margin-top: 32px;
  color: var(--primary-tint-raisin-black-50, #939395);
}

.viewAll {
  margin-top: 10px;
}

.viewAll:hover {
  cursor: pointer;
  color: #f16434;
  font-size: 15px;
}