@import '@styles/_variables.scss';

.container {
  background: white;
  display: flex;
  align-items: center;
}

.image {
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 52px;
  align-self: flex-start;
}

.textDiv {
  background: white;
  box-shadow: 0px 10px 32px 0px #20202014;
  min-width: 639px;
  padding: 56px;
}
@media (max-width: 480px) {
  .textDiv {
    max-width: 300px;
  }
}
@media (max-width: 992px) {
  .textDiv {
    padding: 16px;
    min-width: 0;
    margin: 0 20px;
  }
  .container {
    flex-direction: column;
    margin-top: 250px;
    max-width: none;
    height: 100%;
  }
  .image {
    min-width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: auto;
  }
}
