@import '@styles/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.row {
  width: 100%;
}

.loginHeader {
  text-align: center;
  // background-color: $primary-color;
  // padding: 64px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}

.logo {
  width: 302px;
}

.googleIcon {
  margin-right: 12px;
  margin-bottom: -2px;
}

.title {
  width: 100%;
  max-width: 900px;
  margin: 48px 0;
}

.loginCard {
  margin-bottom: -80px;
  text-align: center;
}
.backgroundContainer {
  position: relative;
  width: 100%;
  height: 90vh;
  /* Makes the background full screen */
  display: flex;
  /* Enables flexbox */
  align-items: center;
  /* Centers items vertically */
  justify-content: center;
  /* Centers items horizontally */
  overflow: hidden;
  /* Prevents scrolling if the image is larger than the screen */
}

.backgroundImage {
  opacity: 0.2;
  position: absolute;
  width: 100%;
  object-fit: cover;
  /* Ensures the image covers the entire container */
  z-index: -1;
  /* Sends the image to the back */
}
