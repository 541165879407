.title {
    font-size: 24px;
    font-weight: bold;
    color: #f16434;
    margin: 0;
}

.counterContainer {
    /* margin-bottom: 7px; */
    height: 26px;
    display: flex;
    padding: 8px;
    align-items: center;
    gap: 8px;
    border-radius: 2px;
    border: 1px solid var(--Secondary-Light-Gray, #D1D7D9);
    background: var(--secondary-tints-light-gray-30, #F1F3F4);
}


.createBoardBtn {
    background-color: #f16434;
    color: #ffffff;
    font-weight: 700;
    font-size: 16px;
}

.createBoardBtn:hover {
    color: #f16434;
    border-color: #ff8e61;
    background: #fff;
    border: 1px solid #f23e00 !important;
    box-shadow: 0px 0px 24px -7px rgb(0 0 0 / 30%);
    -webkit-box-shadow: 0px 0px 24px -7px rgb(0 0 0 / 30%);
    -moz-box-shadow: 0px 0px 24px -7px rgba(0, 0, 0, 0.55);
}