.discoverAdsText {
  font-size: 65px;
  margin-right: auto;
  margin-left: auto;
  width: 1227px;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -1.6px;
  text-align: center;
  border: none;
  background: none;
}

.subHeading {
  margin-top: 13.9px;
  text-align: center;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  margin-right: auto;
  margin-left: auto;
  width: 644px;
}