@import '@styles/_variables.scss';

.containerDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 64px;
  min-height: 110px;
}

.textDiv {
  text-align: center;
  margin-right: 64px;
}

.button {
  margin-bottom: 8px;
  height: max-content;
  min-width: 400px;
  max-width: 450px;
  white-space: pre-wrap;
}

@media (max-width: 768px) {
  .containerDiv {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 24px;
  }
  .textDiv {
    margin-right: 0;
    margin-bottom: 24px;
  }
}
