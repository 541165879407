.loginRouteWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImage {
  opacity: 0.2;
  position: absolute;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}


.bold {
  font-weight: bold;
}

.NP {
  border: 2px solid #ff5e29;
  padding: 2px;
  font-weight: bold;
  color: #ff5e29;
}

.loginFormCard {
  cursor: pointer;
  border: 1px solid #f0f0f0;
  margin-top: 100px;
  background-color: #ffffff;
  width: 100%;
  height: fit-content;
  max-width: 27.5rem;
  box-shadow: 0px 10px 32px rgba(32, 32, 32, 0.04);
  border-radius: 4px;
  padding: 2.5rem;
  gap: 0.5rem;
}

.loginFormCard:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* Slightly stronger shadow */
  border-color: #d9d9d9;
  /* Darker border on hover */
}

.loginForm {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

.label {
  font-weight: bold;
}

.input {
  padding: 0.625rem;
  border: 1px solid #d1d7d9;
  border-radius: 2px;
  outline: #4285f4;
}

.fogotPasswordWrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}

.submitButton {
  background-color: #f16434;
  padding: 14px 70px;
  border: 1px solid transparent;
  border-radius: 2px;
  text-transform: uppercase;
  cursor: pointer;
  color: #f5f5f5;
}

.submitButton:hover {
  background-color: #eb7953;
  color: #f5f5f5;
}

.submitButton:disabled {
  background-color: #a3b0b3;
  cursor: default;
  color: #f5f5f5;
}

.seperator {
  margin: auto;
}

.forgotPassword {
  color: #f16434;
  padding-bottom: 1px;
  padding: 0;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  position: relative;
  cursor: pointer;
}

.forgotPassword::after {
  content: '';
  position: absolute;
  border-bottom: 1px solid tomato;
  top: 100%;
  left: 0;
  right: 0;
}

.registerWrapper {
  margin-top: 1rem;
  font-size: 14px;
  font-weight: 400;
}





.bold {
  font-weight: 700;
}

.checkboxText {
  font-size: 12px;
  line-height: 16px;
}

.checkboxContainer {
  display: flex;
  align-items: center;
}

.error {
  color: #f16434;
  font-size: 14px;
}

.cursorDefault {
  cursor: default;
}



.googleButton {
  width: 100% !important;
  background-color: #4285f4;
  color: #ffffff;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* Center content horizontally */
  border-radius: 2px;
  cursor: pointer;
  position: relative;
  padding: 10px 0;
  /* Add padding for vertical alignment */
}

.googleButton:disabled {
  background-color: gray;
  cursor: default;
}

.googleImageContainer {
  position: absolute;
  left: 1.5px;
  /* Adjust position to keep it on the left */
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
}

.googleButtonText {
  flex: 1;
  /* Take available space for proper centering */
  text-align: center;
  /* margin-right: 20px; */
  /* Prevent overlap with the image */
}