.navbarContainer {
  position: sticky;
  width: 100%;
  height: 86px;
  /* overflow: hidden; */
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 2.5rem;
  border-bottom: 1px solid #dfe3e4;
}

.logoSection {
  display: flex !important;
  flex-grow: 1;
  padding: 1rem 0;
  gap: 4rem;
  align-items: center;
}

.logoSection2 {
  display: flex !important;
  padding: 1rem 0;
  gap: 0.5rem;
  align-items: center;
}

.barSection {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 2rem;
}

.logo {
  max-width: 125px;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  position: relative;
}

.spanLine {
  margin-top: -8px;
  margin-left: -10px;
  border-right: 1px solid #bcbcbc;
  height: 30px;
}

.logoHelperText {
  font-size: 10px;
  align-self: flex-end;
}

.logoText {
  font-size: 24px;
  font-weight: 700;
  color: #26282c;
  line-height: 120%;
  cursor: pointer;
  white-space: nowrap;
}

.NP {
  border: 2px solid #ff5e29;
  padding: 2px;
  font-weight: bold;
  color: #ff5e29;
}

.userTypeSelect {
  width: 220px !important;
  background-color: #f6f7f7 !important;
  border-radius: none !important;
}

.menuSection {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.BellFilled {
  color: #f6f7f7;
}

.DropdownMenuContainer {
  cursor: pointer !important;
  z-index: 9999 !important;
}

.notification {
  width: 40px;
  height: 28px;
  background-color: #f16434;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f6f7f7;
  text-align: center;
  font-size: 14px;
  gap: 4px;
}

.bold {
  font-weight: 700;
}

.link {
  cursor: pointer;
}

.authButtons {
  display: flex;
  gap: 2rem;
}

.buttonSmall {
  padding: 0 2rem !important;
}

.generateReportButton {
  background-color: rgb(29, 29, 230);
  color: white;
  width: 140px;
  height: 38px;
  text-align: center;
  border: none;
}

.generateReportButton:hover {
  cursor: pointer;
  background-color: rgb(66, 66, 233);
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  object-fit: cover;
}

.buttonSection {
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.buttonSectionButton {
  border: none;
  font-weight: bold;
  text-transform: uppercase;
  color: black;
  background: transparent;
  cursor: pointer;
}

/* ............................mac................................................ */
@media only screen and (max-width: 1500px) {
  .barSection {
    gap: 1rem;
  }

  .buttonSection {
    gap: 1rem;
  }
}

.generate_button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 5rem;
}

.generate_report_button {
  background-color: #f16434;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
}

.generate_report_button:hover {
  color: #f16434;
  border-color: #ff8e61;
  background: #fff;
  border: 1px solid #f23e00 !important;
  box-shadow: 0px 0px 24px -7px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0px 0px 24px -7px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0px 0px 24px -7px rgba(0, 0, 0, 0.55);
}

.exportpdf_button {
  height: 100%;
  max-width: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 1rem;
}

.exportpdf_button_sample {
  margin-left: 0rem !important;
}

.exportpdf_button img {
  width: 18px;
  height: 22px;
}

.mobileText {
  /* margin: 0px 0px 0px; */
  font-size: 1.5rem;
  min-height: 100vh;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;

}

.mobileTopText {
  position: fixed;
  inset: 0px;
  z-index: 1001;
  background-color: white;



}

@media screen and (max-width: 1024px) {

  html,
  body {
    overflow: hidden;
  }
}