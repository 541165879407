@import './variables';

.ant-btn {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.ant-btn:not(.ant-btn-primary) {
  border: 1px solid $primary-color;
}

.ant-btn:not(.ant-btn-sm) {
  padding: 12px 24px;

  @media (max-width: 480px) {
    padding: 12px 16px;
    font-size: 14px;
  }
}

.ant-collapse-header {
  border-bottom: 1px solid #d1d7d9;
  margin-top: 8px;
}

// .ant-tabs-tab-btn {
//   font-family: $font-title;
// }

.white-spin .ant-spin-dot-item {
  background-color: #fff;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  position: absolute;
  bottom: 18px;
}

.ant-tabs-tab .ant-tabs-tab-btn {
  text-transform: uppercase;
}

.ant-tooltip-inner {
  padding: 12px !important;
}

span.ant-collapse-header-text {
  width: 100% !important;
}

#customerSubMenu-tmp-0-popup {
  max-height: 400px !important;
  max-width: 300px;
  overflow: auto;
}
#customerSubMenu-tmp-0-popup::-webkit-scrollbar {
  width: 7px !important;
}
#customerSubMenu-tmp-0-popup::-webkit-scrollbar-thumb {
  background-color: #f16434;
  border: 1px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}

#reportCollapse .ant-collapse-content-box {
  padding: 0 !important;
}
.ant-menu-submenu-popup {
  z-index: 0 !important;
}

#modalSelect .ant-select-selector {
  height: 35px;
}
#table .ant-table-cell {
  height: 50px;
  // margin-left: 50px;

  background-color: white;
  // border: none;
}
#table .ant-table-cell {
  padding-left: 16px;
  // border-left: none;
  font-size: 12px;
}
#progressBar .ant-progress-success-bg {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #85ecc1;
}
#modal .ant-modal-close {
  margin-top: 20px;
}
#menuSelect .ant-menu-submenu {
  color: black;
  font-size: 14px;
}
.ant-modal-close-x {
  display: none;
}
#ModalCollapseSetting .ant-collapse {
  border: var(--counter, 1px) solid var(--secondary-tints-light-gray-70, #dfe3e4);
}
#ModalCollapseSetting .ant-collapse-content-box {
  padding: 0px;
}

#ads-preview-modal-table .ant-table-container table > thead > tr:first-child th:first-child {
  background: none;
  border: none;
  padding-left: 19px;
  font-size: 12px;
}

#ads-preview-modal-table .ant-table-thead > tr > th {
  background: none;
  border: none;
  padding-left: 16px;
  font-size: 12px;
}
#ads-preview-modal-table
  .ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

#ads-preview-modal-table .ant-table-tbody > tr > td {
  font-weight: bold;
  padding-top: 28px;
  font-size: 16px;
  color: #26282c;
}
#sidebar-collapse .ant-collapse-borderless > .ant-collapse-item:last-child .ant-collapse-header {
  margin-top: 0px;
  border-top: 1px solid rgb(217, 217, 217);
}

.ads-preview-dropdown .ant-select-item-option:hover {
  color: #f16434;
}

// #AdsPreviewSearchSelect .ant-select-selector {
//   // border: 1px solid #e8ebec !important;
//   border: none;
//   width: 276px;

// }
// #AdsPreviewSearchSelect .ant-select-selector:hover {
//   // border: 1px solid #e8ebec !important;
//   border: 1px solid #f16434;
//   width: 276px;

// }

#modal-phone-border .PhoneInputInput {
  border: none !important;
  margin-left: 16px;
}

#modal-phone-border .PhoneInputInput:focus {
  outline: none !important;
}

.learning-dropdown .ant-select-item-option {
  font-weight: 600;
}

.learning-dropdown .ant-select-item-option:hover {
  color: #f16434;
}

// #learning-center .dashboard_container__A8Q7d {
//   background: #f9fafb;
// }
// #learning-center {
//   background: #f9fafb;
//   padding: 0 !important;
//   margin: 0 !important;
// }
#LearningCenterLearningCard .ant-card-body {
  padding-left: 0px;
  padding-right: 0px;
}
#LearningCenterModal .ant-modal-content {
  border-radius: 8px;
}
#LearningCenterModal .ant-modal-body {
  margin-top: -30px;
}

.Toastify {
  z-index: 1000;
  position: absolute;
}
.ant-select-item-option-content {
  margin-top: 3px;
}

.ant-popover-placement-top {
  z-index: 24182635;
}
.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
  z-index: 240192311;
}

.ant-select-dropdown {
  z-index: 999999999999999999;
  position: absolute;
  text-align: left;
}
#dashboard-collapse .ant-collapse-header {
  border-bottom: none;
}
#ads-card-more-options .ant-btn-text:hover,
.ant-btn-text:active,
.ant-btn-text:focus {
  background: none;
}

#ads-card-more-options .ant-dropdown {
  width: max-content;
}
#campaignDropdown .ant-collapse-header {
  margin-top: 0px;
  margin-left: 8px;
  border-bottom: none;
}
#creativeLibraryDropdown .ant-collapse-header {
  margin-top: 0px;
  margin-left: 8px;
  border-bottom: none;
}

#metrics-table .ant-table-cell {
  // min-width: 200px;
  text-align: center;
}

#navbar-tabs .ant-tabs-nav::before {
  border-bottom: none;
}
#ads-card-more-options .ant-dropdown {
  z-index: 1;
}

#followBrandsCustomTable .ant-table-thead > tr > th {
  /* background-color: transparent; */
  color: #8f8f8f;
  font-weight: 600;
  border: none;
}

#followBrandsCustomTable .ant-table-thead > tr > th::before {
  display: none;
  /* Remove the separator */
}

#followBrandsCustomTable .ant-table-tbody > tr {
  background-color: #ffff;
  border: 3px solid #d1d7d9;
  border-radius: 2px;
  margin: 20px 0 !important;
}

.ant-table-pagination.ant-pagination {
  margin: 16px 16px;
}
#UnAuthInputs .ant-select-selection-placeholder {
  text-align: left !important;
}

.ant-tabs-ink-bar {
  height: 4px !important; /* Adjust the thickness */
  background-color: #f16434 !important; /* Change the color if needed */
}
