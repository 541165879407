.pageWrapper {
  width: 90%;
  margin: 0 auto;
}

.input {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 10.4px 11px;
  color: #000;
  font-size: 16px;
  line-height: 1.5715;
  background-color: #fff;
  background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  width: 100%;
  outline: 0;

  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #ff8e61;
  }
}
