.topAdsContainer {
    background: #f5f5f5;
    text-align: center;
    padding: 40px 20px;
}

.topAdsHeader {
    display: flex;
    align-items: center;
    justify-content: center;
    /* Centers the content horizontally */
    flex-wrap: wrap;
    /* Allows content to wrap on smaller screens */
    gap: 20px;
    /* Adds spacing between the elements */
}

.topAdsTitle {
    font-size: 25px;
    font-weight: 700;
    color: #333;
    margin: 0;
    /* Removes default margins to avoid spacing issues */
}

.keyword {
    color: #f25c05;
    /* Orange highlight for the keyword */
}

.locationContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f7f8f9;
    border: 1px solid #d4d4d5;
    border-radius: 20px;
    padding: 10px 20px;
}

.locationIcon {
    margin-right: 8px;
    width: 20px;
    height: 20px;
}

.locationText {
    font-size: 16px;
    color: #333;
}

.boldText {
    font-weight: bold;
}


.adsContainer {
    margin-top: 35px;
    padding: 0 30px;
}

.carouselItem {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.adImage {
    max-width: 100%;
    padding: 8px;
}