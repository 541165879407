.underlineText {
    color: #f16434;
    padding-bottom: 1px;
    padding: 0;
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: relative;
    border:none;
    background-color: transparent;
    width: fit-content;
    cursor: pointer;
  }
  .underlineText::after {
    content: '';
    position: absolute;
    border-bottom: 1px solid tomato;
    top: 100%;
    left: 0;
    right: 0;
  }