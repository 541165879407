@import url(https://fonts.googleapis.com/css?family=Roboto:500);

$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #3367d6;
$google-bg-disabled: #e2e2e2;
$google-color-disabled: #878787;

.googleBtn {
  width: 100%;
  height: 40px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.25);
  border: 0;
  padding: 2px;
  position: relative;
  cursor: pointer;

  & .googleIconWrapper {
    position: absolute;
    top: 0;
    left: 1.5px;
    margin-top: 1px;
    margin-left: 1px;
    width: 38px;
    height: 38px;
    border-radius: 2px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .googleIcon,
  & .googleIconDisabled {
    width: 18px;
    height: 18px;
  }

  & .googleIcon {
    display: block;
  }

  & .googleIconDisabled {
    display: none;
  }

  & .btnText {
    text-align: center;
    width: calc(100% - 24px);
    float: right;
    // padding-left: 38px;
    margin: 0px 12px;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: 'Roboto';
    font-weight: 500;
    line-height: 14px;
  }

  &:hover {
    box-shadow: 0 0px 1px 2px rgba(66, 133, 244, 0.25);
  }

  &:active {
    background: $button-active-blue;
  }

  &:disabled {
    background-color: $google-bg-disabled;

    & .googleIconWrapper {
      background-color: $google-bg-disabled;
    }

    & .btnText {
      color: $google-color-disabled;
    }

    & .googleIcon {
      display: none;
    }

    & .googleIconDisabled {
      display: block;
    }
  }
}
