.facebookButton {
    width: 100% !important;
    background-color: #4285f4;
    color: #ffffff;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    padding: 8px 0;
    /* Adjust padding for better alignment */
    position: relative;
}

.facebookButton:disabled {
    background-color: gray;
    cursor: default;
}

.facebookButtonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.facebookImageContainer {
    width: 35px;
    height: 35px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    position: absolute;
    left: 1.5px;
    /* Adjust the logo position */
}

.facebookButtonText {
    margin-left: 10px;
    /* Ensure space between logo and text */
}