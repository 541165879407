@import '@styles/_variables.scss';

.wrapper {
  display: flex;
  gap: 32px;
  align-items: center;

  @media (max-width: 636px) {
    flex-direction: row-reverse;
    align-items: start;
    gap: 16px;
  }

  @media (max-width: 398px) {
    gap: 8px;
  }
}

.logo {
  width: 302px;
  @media (max-width: 636px) {
    padding-top: 4px;
    width: 248px;
  }

  @media (max-width: 398px) {
    width: 224px;
  }
}
