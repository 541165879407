@import '@styles/_variables.scss';

.overallHeader {
  margin-top: 80px;
}

.collapseHeader {
  display: flex;
  justify-content: space-between;
  margin-left: 28px;
  width: calc(100% - 28px);
}

@media (max-width: 992px) {
  .overallHeader {
    margin-top: 60px;
    text-align: center;
  }
}

.wastedCollapseGraphsContainer {
  display: flex;
  align-items: center;
}

.youGraph {
  height: 28px;
  background: $primary-color;
  margin-right: 8px;
}

.benchmarkGraph {
  height: 28px;
  background: #a3b0b3;
  margin-right: 8px;
}

.subtitle {
  margin-top: 16px;
}

.graphBar {
  height: 28px;
  margin-right: 8px;
  max-width: 200px;
}

.tooltipText {
  margin-top: 3px;
  margin-left: 8px;
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a3b0b3;
}

.meansList {
  font-weight: bold;
  color: #f16434;
  padding-top: 14px;
  list-style-type: decimal-leading-zero;

  & > li > div {
    font-weight: 400;
    padding: 4px 0;
  }
}

.containerBorder {
  border: 1px solid #d1d7d9;
  padding: 24px;
  height: 100%;
}

.hrMargin {
  margin: 24px 0;
}

.textSubTitle {
  letter-spacing: 0.1rem;
  margin-bottom: 16px;
}

.container_adPerformance {
  display: flex;
  // gap: 32px;
}

.card_adPerformance {
  // width: 50%;
  border: 4px solid #d1d7d9;
  border-bottom: none;
  border-radius: 40px 40px 0 0;
  padding: 28px 14px 0 14px;
}

.bgColorCard_adPerformance {
  background-color: $gray1;
  padding: 21px 10px 10px 10px;
  border-radius: 4px;
}

.content_adPerformance {
  background-color: #fff;
  border-radius: 12px;
  padding: 14px;
  box-shadow: 0px 2px 5px -1px rgba(87, 87, 87, 1);
  border: 1px solid #d1d7d9;
}

.headerCard_adPerformance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.threePoints {
  width: 3px;
  height: 3px;
  display: block;
  background-color: #000;
  border-radius: 99px;
  position: relative;

  &::after,
  &::before {
    content: '';
    display: block;
    border-radius: 99px;
    position: absolute;
    background-color: #000;
    width: 3px;
    height: 3px;
  }

  &::after {
    top: 5px;
  }
  &::before {
    top: -5px;
  }
}

.infoContainer_adPerformance {
  display: flex;
  align-items: center;
  // width: 45%;
}

.inforContent_adPerformance {
  display: flex;
  width: 100%;
}

.wrapperInfo_adPerformance {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.infos_adPerformance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 12px;
}

.divider {
  display: block;
  height: 64px;
  width: 1px;
  background-color: #d1d7d9;
}
a span {
  text-decoration: underline;
  color: blue;
  cursor: pointer;
}

// @media only screen and (max-width: 1500px) and (min-width: 1400px){
//   .macCol16{
//     max-width: 55%!important;
//   }
//   .macCol8{
//     max-width: 27%!important;
//   }
// }
.extraMetricsContainer {
  max-width: max-content;
}
