/* .CardCustom {
  width: 600px;
  height: 232px;
  flex-shrink: 0;
} */

.cardContent {
  display: flex;
  align-items: center;
}

.RightContent {
  padding-left: 16px;
}

.CardImage {
  width: 120px;
  height: 120px;
}

.Tag {
  border-radius: 4px;
  background: var(--secondary-tints-light-gray-30, #f1f3f4);
  color: var(--primary-tints-dark-gray-50, #8f8f8f);
  text-align: center;
  /* margin-top: 0px; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.029px;
  /* 185.924% */
  margin-bottom: 8px;
}

.AlertDetail {
  width: 500px;
  height: 40px;
  color: var(--primary-tints-dark-gray-50, #8f8f8f);
  /* leading-trim: both;
text-edge: cap;
font-family: Geomanist; */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 23.2px */
}

.CardActions {
  display: flex;
  justify-content: space-between;
}

.ButtonCustom {
  border: none;
  /* padding: 20px;
  margin-top: 40px; */
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
}

.ButtonCard {
  color: var(--Primary-Ubersuggest-Orange, #f16434);
  /* leading-trim: both;
text-edge: cap;
font-family: Geomanist; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 26.1px */
  /* text-decoration-line: underline; */
  border-bottom: 2px solid #f16434;
  /* background-color: #f1f3f4; */
}

.RightArrow {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-left: 8px;
}

.cardNavigation {
  display: flex;
  align-items: center;
}



.test {
  width: 12px;
  height: 12px;
  border-radius: 2px;
  background: var(--Secondary-Gray, #A3B0B3);
}