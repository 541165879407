@import '@styles/_variables.scss';

.header {
  background: $primary-color;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 430px;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 16px;
}

.headerLeftSide {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 60px;
}

.performanceCard {
  display: flex;
  justify-content: flex-end;
}

.logoDiv {
  & > img {
    max-width: 302px;
  }
}

.logoRow {
  margin-top: 48px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.notGoogleAdsAcc{
  // color: yellow;
  margin-top: 5px;
  margin-bottom: 20px;
  line-height: 1.2em;
    font-size: 20px;
    color: rgb(255, 255, 0);
}
@media (max-width: 992px) {
  .header {
    flex-direction: column;
    height: auto;
    text-align: center;
    align-items: center;
    padding: 8px;
  }
  .performanceCard {
    width: 100%;
    position: relative;
    top: 56px;
  }
}
