.registerContainer {
  width: 100%;
  overflow: hidden;
}
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 120px);
}
