.crossButton {
  position: absolute;
  top: 0px;
  right: 0px;
}

.closeButton {
  cursor: pointer;
  color: black;
  height: 20px;
  width: 20px;
}

.modalContainer {
  margin: 40px 122px;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
}

.shareContainer {
  display: flex;
  justify-content: space-between;
}

.shareContainer2 {
  display: flex;
  gap: 6px;
  cursor: pointer;
  align-items: center;
}
