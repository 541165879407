.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.label {
  font-weight: bold;
}
.input {
  padding: 0.625rem;
  border: 1px solid #d1d7d9;
  border-radius: 2px;
  outline: #4285f4;
  width: 100%;
}
.inputContainer {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
}
.endAdornment {
  position: absolute;
  right: 1rem;
  z-index: 100;
}
.errorBorder {
  border: 1px solid tomato;
}
.isError {
  color: tomato;
}

.isSuccess {
  color: rgb(5, 165, 5);
}
