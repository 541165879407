.custom-table .ant-table-thead > tr > th {
  background-color: transparent;
  color: #8f8f8f;
  font-weight: 600;
  border: none;
}

.custom-table .ant-table-thead > tr > th::before {
  display: none; /* Remove the separator */
}

.custom-table .ant-table-tbody > tr {
  background-color: #ffff;
  border: 3px solid #d1d7d9;
  border-radius: 2px;
  margin: 20px 0 !important;
}

.listItem,
.ant-list-item-meta {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.deleteIcon {
  color: tomato;
}
.capitalize {
  text-transform: capitalize;
}
