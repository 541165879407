/* .logoSection2 {
  display: flex !important;
  padding: 1rem 0 1.5rem 0;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
.logo {
  max-width: 200px;
  display: flex;
  flex-direction: column;
  margin-right: 1rem;
  position: relative;
}
.logo::after {
  content: '';
  position: absolute;
  top: 15px;
  right: -15px;
  border-right: 1px solid #bcbcbc;
  height: 30px;
}
.logoText {
  font-size: 24px;
  font-weight: 700;
  color: #26282c;
  line-height: 120%;
  cursor: pointer;
}
.logoHelperText {
  font-size: 10px;
  align-self: flex-end;
}
.NP {
  border: 2px solid #ff5e29;
  padding: 2px;
  font-weight: bold;
  color: #ff5e29;
} */

/* .............................................................. */
.radioWrapper {
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 1rem;
  row-gap: 1rem;
}

.radioItem {
  min-width: fit-content;
  border: 1px solid #dfe3e4;
  padding: 4px 8px;
  border-radius: 2px;
}
.radioItem label {
  display: flex;
  gap: 5px;
}

.active {
  border: 1px solid #f16434;
  background: rgba(254, 239, 235, 0.3);
}
.error {
  color: rgb(243, 93, 93);
}
.btn_div {
  text-align: center;
  margin-top: 16px;
  /* margin-bottom: 3.5rem; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.skipButton::after {
  border: none;
}
.ant-modal-header {
  border: 'none';
}
.modal_heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.welcome_heading {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 1.3rem;
}
.goback_button_div {
  display: flex;
  justify-content: center;
}
.goback_button_top_div {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}
.goback_button_top {
  font-size: 14px;
  font-weight: 500;
  text-transform: none !important;
  background-color: white;
  color: #a3b0b3;
  padding: 0px 0px !important;
  border: none !important;
  cursor: pointer;
  letter-spacing: 1.12px;
}
.goback_button_top:hover {
  background-color: white;
  color: #a3b0b3;
  border: none !important;
}
.goback_button {
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: white;
  color: #a3b0b3;
  padding: 17px 0px;
  border: none !important;
  cursor: pointer;
  letter-spacing: 1.12px;
}
.goback_button:focus {
  border: none !important;
}
.goback_button:hover {
  background-color: white;
  color: #a3b0b3;
  border: none !important;
}
.continue_button {
  height: 59px;
  padding: 17px 70px;
  margin-bottom: 0.5rem;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1.12px;
}
.ant-spin-dot-item {
  background-color: red;
}
.placeholder_please_select {
  font-size: 14px;
  color: #a3b0b3;
}
