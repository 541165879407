.card {}

.profile {
  display: flex;
  gap: 10px;
}

.profileIcons {
  margin-top: 6px;
  height: 12px;
  width: 12px;
  opacity: 0.6;
}

.cardImageContainer {
  /* min-height: 420px; */
  /* margin-top: 45px; */
  /* max-height: 420px; */
  width: -webkit-fill-available;
  /* margin-top: 24px; */
  /* max-height: 288px; */
  /* background: #F6F7F7; */
  text-align: center;
}

.cardImage {
  width: -moz-available !important;
  /* min-height: 420px; */
  width: 444px;
  /* height: 480px; */
  /* margin-top: 45px; */
  object-fit: contain;
  max-height: -moz-available;
  max-width: -webkit-fill-available !important;
}

.signupContainer {
  background: #ecf3fe;
  min-height: 84px;
  display: flex;
  justify-content: space-between;
}

.signupTextContainer {
  margin-top: 16px;
  margin-left: 16px;
}

.signupButtonContainer {
  margin-top: 30px;
  margin-right: 30px;
}

.cardButton {
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  border: 1px solid #3c3b41;
  background: var(--Secondary-Onyx, #3c3b41);
  color: var(--Primary-White, #fff);
  font-size: 12px;
  font-style: 500;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
  cursor: pointer;
}

.shareContainer {
  display: flex;
  justify-content: space-between;
}

.shareTextContainer {
  display: flex;
  gap: 6px;
  cursor: pointer;
}