.acceptInviteContainer {
  width: 100%;
  height: calc(100vh - 180px);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -100px;
}

.heading {
  color: #F16434 !important;
  margin: 1rem ;
  text-align: center;
}
