.passwordResetWrapper {
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 86px);
    .bold {
      font-weight: bold;
    }
    .NP {
      border: 2px solid #ff5e29;
      padding: 2px;
      font-weight: bold;
      color: #ff5e29;
    }
  }
  
  .loginFormCard {
    background-color: #ffffff;
    width: 100%;
    height: fit-content;
    max-width: 27.5rem;
    box-shadow: 0px 10px 32px rgba(32, 32, 32, 0.04);
    border-radius: 4px;
    padding: 2.5rem;
    gap: 0.5rem;
    .loginForm {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    .inputWrapper {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;
    }
    .label {
      font-weight: bold;
    }
    .input {
      padding: 0.625rem;
      border: 1px solid #d1d7d9;
      border-radius: 2px;
      outline: #4285f4;
    }

    .submitButton {
      background-color: #a3b0b3;
      padding: 14px 70px;
      border: 1px solid transparent;
      border-radius: 2px;
      text-transform: uppercase;
      cursor: pointer;
    }
    .submitButton:hover {
      background-color: #f16434;
      color: #f5f5f5;
    }
    .seperator {
      margin: auto;
    }
  }


  .bold {
    font-weight: 700;
  }
  .checkboxText {
    font-size: 12px;
    line-height: 16px;
  }
  .checkboxContainer {
    display: flex;
    align-items: center;
  }
  .required {
    color: #f16434;
  }
  