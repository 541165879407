.userTypeSelect {
  width: 100% !important;
  background-color: #f6f7f7 !important;
  border-radius: none !important;
  min-width: 170px;
}
.loadingContainer {
  min-width: 170px;
  width: 100%;
  max-width: 358px;
  display: flex;
  justify-content: center;
}

.startAdornment {
  width: 25px;
  height: 25px;
  background-color: #f59271;
  border-radius: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: white;
}
.menuLabel {
  display: flex;
  gap: 0.6rem;
  align-items: center;
}
.menuItem {
  border: 1px solid #d1d7d9;
}
.customerLabel {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.labelAction {
  display: flex;
  gap: 20px;
}
.icon {
  margin-top: 10px;
  cursor: pointer;
}
.loading {
  margin-top: 8px;
  margin-left: 2px;
}
.name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 5px;
  min-width: 200px;
}
.genetateContent {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.generateActions {
  display: flex;
  justify-content: end;
  gap: 1rem;

  button {
    padding: 0 2rem !important;
  }
}
.overflowThreeDots {
  white-space: nowrap;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* ............................mac................................................ */
@media only screen and (max-width: 1500px) and (min-width: 1400px) {
  .startAdornment {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
  .menuLabel {
    gap: 0.3rem;
  }
  .generateActions {
    display: flex;
    justify-content: end;
    gap: 1rem;

    button {
      padding: 0 2rem !important;
    }
  }
  .overflowThreeDots {
    width: 120px;
  }
}

.generateContent {
  margin-right: 30px;
}
