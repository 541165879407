.collapsePadder {
  padding: 48px;
  position: relative;
}

.hrMargin {
  margin: 30px 0;
}
.improvementCol {
  width: 100%;
  // max-height: 286.14px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
  gap: 16px;
  margin: 16px 0;
}
.hiddenImprovementCol {
  animation-name: hiddenCol;
  height: 0px;
  animation-duration: 1s;
}
.wrapperInfo_adPerformance {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
  align-items: center;
}
.infos_adPerformance {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px 12px;
}
.divider {
  display: block;
  height: 64px;
  width: 1px;
  background-color: #d1d7d9;
}

.showImprovementCol {
  animation-name: showCol;
  // animation-duration: 1.5s;
  animation-duration: 0s;
}
.text {
  line-height: 28px;
}

.scoreCard {
  max-width: 100%;
}
@media (max-width: 992px) {
  .collapsePadder {
    padding: 0;
  }

  .scoreCard {
    display: none;
  }
  .improvementCol {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 559px) {
  .improvementCol {
    grid-template-columns: repeat(1, 1fr);
  }
}

.boxShadown {
  width: 100%;
  height: 100%;
  position: absolute;
  backdrop-filter: blur(6px);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
}

.modalContent {
  text-align: center;
  width: 90%;
  max-width: 550px;
  background-color: #fff;
  box-shadow: 1px 1px 10px rgb(168, 168, 168);
  padding: 48px;
}
.btnGhostDisableStyle:disabled {
  border-color: #d9d9d9 !important;
  cursor: default;
}
