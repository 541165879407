.title {
  color: var(--Primary-Orange, #f16434);
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
}

.filterButtonContainer {
  display: flex;
  gap: 4px;
  cursor: pointer;
}

.filterButton {
  background: none;
  font-size: 14px;
  border: none;
  cursor: pointer;
}

.tabTitle {
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 300;
}

.clearTag {
  cursor: pointer;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  margin-left: 20px;
  text-decoration-line: underline;
}

.clearTag:hover {
  color: #f16434;
  font-size: 17px;
}