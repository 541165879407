.alert {
    color: var(--Primary-Dark-Gray, #202020);


    font-weight: 400;
    line-height: 16px;

    text-decoration-line: underline;
    margin-right: 12px;
}

.profitless {
    color: var(--Primary-Dark-Gray, #202020);

    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;

    margin-left: 12px;
}

.slash {
    color: var(--Secondary-Gray, #A3B0B3);

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    gap: 12px;
}

.orangeProfitless {
    color: var(--Primary-Orange, #F16434);
    /* font-weight: 700; */
    line-height: 26px;

    font-size: 22px !important;

    margin-right: 12px;
}

.toggle {
    margin-left: 12px;

}


.dash {
    fill: var(--Primary-White, #FFF);
    stroke-width: 1px;
    stroke: var(--Secondary-Light-Gray, #D1D7D9);
    width: 0px;
    height: 24px;
}

.alertsEnable {
    color: var(--Primary-Raisin-Black, #26282C);



    font-weight: 400;
    margin-left: 24px;
}

.dollarImage {
    margin-right: 8px;
}

.informationImage {
    margin-left: 6px;
}

.numbersClass {
    display: flex;
    margin-top: 41px;
}

.numberClassFirst {
    color: var(--Primary-Dark-Gray, #202020);

    font-size: 28px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 100% */
    margin-right: 5px;
}

.numberClassSecond {
    color: var(--secondary-tints-onyx-70, #77767A);

    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 28px;
    /* 140% */
}

.goodKeyWords {
    color: var(--Primary-Dark-Gray, #202020);


    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */

}

.goodKeyWordsRectangle {
    border-radius: 2px;
    background: #85ECC1;
    width: 10px;
    height: 10px;
    flex-shrink: 0;
    margin-top: 3px;
    margin-right: 6px;
}

.goodKeyWordsProfitLess {
    border-radius: 2px;
    background: #FE8485;
    width: 10px;
    height: 10px;
    margin-top: 3px;
    margin-right: 6px;
    flex-shrink: 0;
}

.requestNewFormulas {
    color: var(--Primary-Ubersuggest-Orange, #F16434);

    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 145%;
    /* 26.1px */
    text-decoration-line: underline;
    margin-right: 8px;

}

.cardHeaderSetting {
    display: flex;
    justify-content: space-between;
}

.lastDaysSpent {
    color: #A3B0B3;
}

.table {
    margin-top: 35.5px;

}

.reloadImage {
    margin-right: 8px;
    width: 17.418px;
    height: 16.667px;
    flex-shrink: 0;

}

.restoreToDefault {
    color: var(--Primary-NP-Digital-Orange, #FF5E29);
    text-align: right;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    text-decoration-line: underline;
}

.notificationLevels {
    color: var(--Primary-Dark-Gray, #202020);
    font-size: 22px;
    font-weight: 600;
}

.stayUpdated {
    color: var(--Secondary-Gray, #A3B0B3);
    max-width: 303px;
    margin-top: 26px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 128.571% */
}

.and {
    border: 1px solid var(--Primary-NP-Digital-Orange, #FF5E29);
    background: rgba(255, 239, 234, 0.50);
    align-items: center;
    display: flex;
    text-align: center;
    max-width: 40px;
    margin-right: 16px;
    justify-content: center;
    max-height: 106px;
    margin-top: 38px;
}



.clicks {

    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: var(--Primary-Orange, #F16434);

}

.orangeDot {
    margin-top: 19px;
    display: flex;
    color: var(--Primary-Dark-Gray, #202020);
    margin-left: 18px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.blueDot {

    margin-top: 19px;
    display: flex;
    color: var(--Primary-Dark-Gray, #202020);
    margin-left: 18px;

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.conersion {
    margin-right: 8px;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    background: var(--Primary-Orange, #4285F4);
}

.periodSpan {
    color: var(--Secondary-Gray, #A3B0B3);

    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
}

.goalPercentage {
    margin-top: 19px;
    display: flex;
    color: var(--Secondary-Light-Gray, #D1D7D9);
    margin-left: 18px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    /* 16px */
}

.goalPercentageNumber {
    margin-left: 8px;
    color: var(--Primary-Dark-Gray, #202020);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.goalPercentageNumber2 {
    margin-top: 16px;
    margin-left: 16px;

    font-size: 14px;

    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
}

.pauseAds {
    margin-top: 24px;


}

.exampleLine {
    border-radius: 2px;
    background: var(--Secondary-Blue, #4285F4);
    width: 2px;
    height: 45px;
    flex-shrink: 0;
    margin-right: 6px;
}

.saveAsDraft {
    color: var(--Secondary-Gray, #A3B0B3);
    justify-content: space-between;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    /* 114.286% */
    text-decoration-line: underline;
}

.cancel {
    color: var(--Primary-Orange, #F16434);
    border: none !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}

.saveAndPublish {
    border-radius: 2px;
    background: var(--Primary-Orange, #F16434);
    color: var(--White-White, #FFF);
    margin-left: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: normal;
}