.loadingContainer {
    width: 100%;
    min-height: 40vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.clearText:hover {
    color: var(--Primary-Orange, #F16434);
}