.platformName {
  margin-top: 24px;
  color: var(--primary-tint-raisin-black-70, #67696b);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.description {
  margin-top: 16px;
  color: var(--Primary-Raisin-Black, #26282c);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 157.143%;
}

.buttonContainer {
  margin-top: 24px;
  display: flex;
  gap: 16px;
}

.learnButton {
  display: inline-flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 2px;
  background: var(--Secondary-Onyx, #3c3b41);
  border: 1px solid #3c3b41;
  cursor: pointer;
  color: var(--Primary-White, #fff);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.12px;
  text-transform: uppercase;
}

.copyCTA {
  color: var(--Primary-Dark-Gray, #202020);
  font-size: 14px;
  font-style: normal;
  font-weight: bold;
  line-height: normal;
  cursor: pointer;
  border: none;
  background: none;
}