@import '@styles/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.row {
  width: 100%;
}

.loginHeader {
  text-align: center;
  background-color: $primary-color;
  padding: 64px 16px 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 80px;
}

.logo {
  width: 302px;
}

.title {
  width: 100%;
  max-width: 650px;
  margin: 48px 0;
}

.loginCard {
  margin-bottom: -80px;
  text-align: center;
}
.loginCard {
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  border-radius: 8px;
  padding: 20px;
}
.backgroundContainer {
  position: relative;
  width: 100%;
  height: 90vh; /* Makes the background full screen */
  display: flex; /* Enables flexbox */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  overflow: hidden; /* Prevents scrolling if the image is larger than the screen */
}

.backgroundImage {
  opacity: 0.2;
  position: absolute;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
  z-index: -1; /* Sends the image to the back */
}
