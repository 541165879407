@import '@styles/_variables.scss';

.container {
  display: flex;
  align-items: center;
  text-align: center;
}

.progressBar {
  height: 8px;
  width: 100px;
  background: #f1f3f4;
  border-radius: 16px;
  margin-left: 8px;
}

.percentage1 {
  color: #fe8485;
}
.percentage2 {
  color: #f4e26c;
}
.percentage3 {
  color: #85ecc1;
}
.progress-percentage1 {
  background: #fe8485;
}
.progress-percentage2 {
  background: #f4e26c;
}
.progress-percentage3 {
  background: #85ecc1;
}
