.crossButton {
  position: absolute;
  top: 0px;
  right: 0px;
}
.closeButton {
  cursor: pointer;
  color: black;
  height: 20px;
  width: 20px;
}
.arrayValues {
  color: black;
  cursor: pointer;
}
.arrayValues:hover {
  font-weight: 700;
  color: #F16434;
}
.objectValues {
  color: black;
  cursor: pointer;
}
.objectValues:hover {
  font-weight: 700;
  color: #F16434;
}