.HeadingContainer {
  display: flex;
  align-items: center;
}

.Heading {
  color: var(--Primary-Orange, #f16434);
  /* leading-trim: both;
text-edge: cap;
font-family: Geomanist; */
  font-size: 22px;
  font-style: normal;
  /* font-weight: 500; */
  line-height: 26px;
  /* 118.182% */
}

.SubHeading {
  color: var(--Primary-Dark-Gray, #202020);
  /* leading-trim: both;
  text-edge: cap;
  font-family: Geomanist; */
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.EllipseCustom {
  width: 6px;
  height: 6px;
  background-color: #d1d7d9;
  border-radius: 50%;
  margin-left: 10px;
  margin-right: 10px;
}

.newFormulaLink {
  color: var(--Primary-NP-Digital-Orange, #ff5e29);
  text-align: right;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  text-decoration-line: underline;
  margin-left: 8px;
}

.image {
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 20px;
  align-self: flex-start;
  background-color: white;
  box-shadow: 0px 3px 32px 0px #a1a1a133;
  background-color: #ffffff;
}

.container {
  background: white;
  display: flex;
  align-items: center;
}

.quoteContent {
  box-shadow: 0px 10px 32px 0px #20202014;
  background-color: white;
  padding: 16px 24px 16px 24px;
  /* font-family: 'Geomanist'; */
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: left;
  width: 100%;
}

/* modal style  temporary $$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$$*/
.Tag {
  margin-top: 20px;

  border-radius: 4px;
  background: var(--secondary-tints-light-gray-30, #f1f3f4);
  color: var(--primary-tints-dark-gray-50, #8f8f8f);
  text-align: center;
  /* leading-trim: both;
  text-edge: cap;
  font-family: Geomanist; */
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26.029px;
  /* 185.924% */
  margin-bottom: 8px;
}

.AlertDetail {

  color: var(--Primary-Dark-Gray, #202020);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 19.6px */


  margin-bottom: 32px;
}

.ModalParent {
  display: flex;
  /* justify-content: space-around; */
  margin-bottom: 15px;
}

.wraperOrange {
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-right: 8px;
}

.ModalOrangeBox {
  width: 8px;
  height: 8px;
  background-color: #ff5e29;
  /* margin-right: 16px; */
}

.DefaultFormula {
  width: 662px;
  min-height: 168px;
  margin: 10px;
  margin-top: -5px;
  /* margin-right: 32px; */
  /* margin-left: 32px;   */
  /* margin-right: 32px; */
  /* border-radius: 2px; */
  border: 1px solid var(--secondary-tints-light-gray-70, #dfe3e4);
  background: #f9fbff;
  padding: 20px;
  /* margin-bottom: 32px; */
}

.AND {

  justify-content: center;
  text-align: center;
  align-items: center;
  display: flex;
  height: 81px;
  border: 0.732px solid var(--Primary-NP-Digital-Orange, #FF5E29);
  background: rgba(255, 239, 234, 0.50);

}

.andText {

  /* margin-top: 28.71px; */
  color: var(--primary-tint-orange-70, #FF8E69);
  font-size: 11.712px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 11.712px */
}

.modalClicksCol {
  display: flex;
  position: absolute;
  top: 0px;
  left: 52px;

}

.reloadImage {
  width: 17.418px;
  height: 16.667px;
  flex-shrink: 0;
}

.modalClicksText {

  color: gray;
  margin-left: 11.71px;
  margin-top: 11.71px;

  /* margin-bottom: 11.43px; */
  /* margin-right: 84.53px; */
  font-size: 10.248px;
  font-style: normal;
  font-weight: 400;
  line-height: 11.712px;
  /* 114.286% */

}

.modalConversionsCol {
  display: flex;
  position: absolute;
  top: 43px;
  left: 138px;
}

.ModalExampleContent {
  width: 165px;
  color: var(--Primary-Raisin-Black, #26282C);

  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 145%;
  /* 20.3px */
}


/*Start of Styling for guided flow */
.mainContent {
  /* padding: 0%; */
  /* width: 274px; */
  height: 110px;
  /* background-color: rgba(38, 40, 44, 1); */
}

.nextButton {
  border-radius: 4px;
  background: var(--Secondary-Blue, #226CE0) !important;
  width: 45px;
  text-align: center !important;
  height: 20px;
  padding: 0px !important;
  border: none !important;
  flex-shrink: 0 !important;

}

.textDetails {
  margin-top: 8px;
  color: white;
  max-width: 176px;
  height: 48px;
}

.backButton {
  margin-top: 2px;
  width: 30px;
  height: 12px;
  color: white;
  margin-right: 6px;
}

.progressText {
  color: #939395;
}

.formulaText {
  color: var(--Secondary-Gray, #A3B0B3);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 16px;
  letter-spacing: -0.2px;
}