@import '@styles/_variables.scss';

.fontTitle {
  font-family: $font-title;
}
.fontText {
  font-family: $font-text;
}

@each $size in $sizes {
  .font#{$size} {
    font-size: #{$size}px;
  }
}

.primary {
  color: $primary-color;
}
.secondary {
  color: $secondary-color;
}

.white {
  color: $white-color;
}

.black {
  color: $black-color;
}

.gray {
  color: $gray1;
}

.error {
  color: $error;
}

.darkerGray {
  color: #797979;
}

.isGenericBold {
  font-weight: 500;
}
.isMediumBold {
  font-weight: 600;
}
.isSemibold {
  font-weight: 400;
}

.isBold {
  font-weight: 700;
}
.cursor {
  cursor: pointer;
}

.isUppercase {
  text-transform: uppercase;
}

.percentage1 {
  color: #fe8485;
}
.percentage2 {
  color: #f4e26c;
}
.percentage3 {
  color: #85ecc1;
}
