.parentDiv {
  /* display: flex; */
  /* margin-top: 22px; */
  /* padding: 24px; */
  /* gap: 24px; */
  /* justify-content: space-between; */
}

.inputContainer {
  /* display: flex; */
  /* margin-left: 24px; */
  /* margin-top: 24px; */
  /* gap: 24px; */
  /* flex: 1; */
}

.input {
  /* flex: 1; */
  height: 48px;
}

.inputText {
  font-size: 14px;
  color: #939395;
  /* border: 1px solid #D1D7D9; */
}



.searchButton {
  border: 1px solid #f16434;
  width: -webkit-fill-available;
  width: -moz-available;
  border-radius: 2px;
  background: #f16434;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.12px;
  height: 48px;
  cursor: pointer;
}

.searchButton:hover {
  background-color: #ffffff;
  color: #f16434;
  border: 1px solid #f16434;
}

.searchButton:disabled {
  cursor: not-allowed;
  /* Indicate it's not clickable */
  opacity: 0.6;
  /* Dim the button to indicate disabled state */
  background-color: #B3B3B3;
  color: #ffffff;
  border: 1px solid #B3B3B3;
}

/* Prevent hover effect when the button is disabled */
.searchButton:disabled:hover {
  background-color: #B3B3B3;
  color: #ffffff;
  border: 1px solid #B3B3B3;
}

.filterButton {
  padding: 12px;
  border: 1px solid #fff;
  border-radius: 2px;
  background: #fff;
  color: #202020;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}

.dropdownContainer {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  flex: 1;
  margin-left: 24px;
  margin-right: 24px;
  margin-top: 20px;
  padding-bottom: 20px;
}

.options {
  display: flex;
  /* align-items: center; */
  /* text-align: center; */
  /* margin-top: 4px; */
}

.checkbox {
  font-size: 14px;
  /* line-height: 16px; */
  margin-left: 12px;
  font-weight: bold;
}

.selectBorder {
  border: 1px solid #e8ebec !important;
}