@import '@styles/_variables.scss';

.wrapperMain {
  border: 1px solid #d1d7d9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  min-height: 208px;
  justify-content: space-between;
}

.wrapperCards {
  display: flex;
  justify-content: space-between;
}

.cardPriority {
  padding: 6px 8px;
  border: 1px solid;
  border-radius: 2px;

  &.high {
    color: #fe8485;
    background-color: rgba(254, 132, 133, 0.5);
  }
  &.medium {
    color: #f8bc54;
    background-color: rgba(248, 188, 84, 0.5);
  }
  &.low {
    color: #85ecc1;
    background-color: rgba(133, 236, 193, 0.5);
  }
}

.wrapperPriority {
  display: flex;
  align-items: center;
  gap: 4px;
}

.wrapperContent {
  padding-top: 24px;
}
.iconWrapper {
  margin-top: 24px;
  margin-bottom: 12px;
}
.buttonWrapper {
  border-top: 1px solid #d1d7d9;
  padding: 16px;
}
