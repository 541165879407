.keyFeatures {
    text-align: left;
    font-size: 56px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: -1.12px;
}

/* .searchBarContainer {
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0 auto;
    max-width: 600px;
} */