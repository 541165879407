@import '@styles/_variables.scss';

.container {
  background: white;
  height: 250px;
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #d1d7d9;
  text-align: center;
  max-width: 100%;
}
.titleDiv {
  position: block;
  display: flex;
  gap: 8px;
  margin-top: 32px;
}
.tooltipText {
  margin-top: 5px;
  font-size: 12px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #a3b0b3;
}

.percentageDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -24px;
}
.percentageDiv span {
  font-size: 50px;
  font-family: $font-title;
}

.progressBar {
  height: 10px;
  width: 200px;
  background: #f1f3f4;
  border-radius: 16px;
}

.percentage1 {
  color: #fe8485;
}
.percentage2 {
  color: #f4e26c;
}
.percentage3 {
  color: #85ecc1;
}
.progress-percentage1 {
  background: #fe8485;
}
.progress-percentage2 {
  background: #f4e26c;
}
.progress-percentage3 {
  background: #85ecc1;
}

@media screen and (max-width: 480px) {
  .container {
    width: 100%;
  }
}
