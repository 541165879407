@import '@styles/_variables';

.arrow {
  display: block;
  width: 6px;
  height: 6px;
  border-bottom: 1px solid;
  border-right: 1px solid;
  transform: rotate(45deg);
  transition: all ease 0.2s;
  color: #fff;
  margin-bottom: 1px;
}

.container_switch {
  display: inline;
  position: relative;

  &:hover .dropdown {
    overflow: visible;
    height: 227px;
  }
}

.box_language {
  padding: 4px 8px;
  display: inline-flex;
  gap: 16px;
  align-items: center;
  transition: all ease 0.2s;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  z-index: 99999999999;

  &:hover {
    color: #fff;
  }

  &:hover .arrow {
    transform: rotate(-135deg);
    color: #fff;
  }
}

.dropdown {
  position: absolute;
  z-index: 999999;
  transform: translate(6px, 0px);
  overflow: hidden;
  height: 0px;

  &:hover {
    overflow: visible;
    height: 227px;
  }
}

.list {
  box-sizing: border-box;
  margin: 0;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: 'tnum', 'tnum';
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 8px 16px;
  color: #000;
  font-size: 16px;
  line-height: 1.5715;
  background-color: #fff;
  // background-image: none;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
  transition: all 0.3s;
  outline: 0;
  z-index: 9999999999;

  &:hover,
  &:focus {
    background-color: #fff;
    border-color: #ff8e61;
    z-index: 99999999;
  }
}

.itens {
  padding: 1px 0;
}

.button {
  padding: 0 !important;
  height: auto !important;
  border: none !important;
  color: #202020 !important;
  width: 100% !important;
  text-align: start !important;
  font-size: 12px !important;
  text-transform: none !important;
  letter-spacing: 0.7px !important;
  z-index: 9999999999;

  & > span {
    width: 100%;
    padding: 4px 16px !important;
    transition: all ease 0.2s;
  }

  &:hover > span {
    color: #f16434;
  }
}
