.manageUsersContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 4rem;
}

.progressContainer {
  max-width: 261px;
  overflow: hidden;
}

.upgradeButton {
  background: #4285f4;
  border-radius: 2px;
  width: 168px;
  height: 32px;
  text-align: center;
  border: none;
  color: white;
  cursor: pointer;
}

.upgradeButton:hover {
  background-color: #f16434;
}

.emailInput {
  max-width: 358px;
  width: 100%;
  border: 1px solid rgb(218, 215, 215);
  min-height: 48px;
  padding: 0 10px;
  border-radius: 2px;
  flex-grow: 1;
}

.emailInput:focus {
  outline: none;
}

.inputContainer {
  position: relative;
  width: 100%;
}

.accountContainer {
  margin: 1rem 0;
}

.formContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
  max-width: 700px;
  margin-bottom: 20px;
  width: 100%;
}

.inviteButton {
  padding: 0 2rem !important;
  font-size: 14px;
  /* width: 153px; */
  height: 48px;
  background-color: #f16434;
  border: none;
  color: white;
  border-radius: 3px;
}

.inviteButton :disabled {
  padding: 0 2rem !important;
  font-size: 14px;
  /* width: 153px; */
  height: 48px;
  background-color: gray;
  border: none;
  color: black;
  border-radius: 3px;
}



.inviteButton2 {
  padding: 0 1rem !important;
}

.inviteButton i {
  background-color: white;
}

.inviteButton:hover {
  /* cursor: pointer; */
  background-color: #f0815c;
}

.inviteButton:disabled :hover {
  color: black;
  background-color: #939395;
  border: 1px solid #939395;
  cursor: not-allowed;
  opacity: 0.5;
}

.capitalize {
  text-transform: capitalize;
}

@media only screen and (max-width: 750px) {
  .formContainer {
    flex-direction: column;
    align-items: start;
  }

  .emailInput {
    width: 100%;
  }

  .inputContainer {
    margin-bottom: 20px;
  }
}

.error {
  color: tomato;
  position: absolute;
  top: 55px;
}

.loadingContainer {
  min-width: 170px;
  width: 100%;
  max-width: 358px;
  display: flex;
  justify-content: center;
}