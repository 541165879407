@import './antd-overwrites.scss';

@font-face {
  font-family: 'geomanist-bold';
  src: url('../fonts/geomanist-bold-webfont-webfont.woff2') format('woff2'),
    url('../fonts/geomanist-bold-webfont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist-medium';
  src: url('../fonts/geomanist-medium-webfont-webfont.woff2') format('woff2'),
    url('../fonts/geomanist-medium-webfont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'geomanist-regular';
  src: url('../fonts/geomanist-regular-webfont-webfont.woff2') format('woff2'),
    url('../fonts/geomanist-regular-webfont-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'geomanist-regular', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.customScrollbar::-webkit-scrollbar {
  width: 7px !important;
  background-color: rgb(235, 236, 238);
}
.customScrollbar::-webkit-scrollbar-thumb {
  background-color: #f16434;
  border: 1px solid transparent;
  border-radius: 10px;
  background-clip: content-box;
}
.ant-modal-header {
  border-bottom: none;
}
.ant-table-thead > tr > th {
  padding: 0;
}
.ant-menu-submenu {
  z-index: 9999 !important;
}

/* YourStyles.css */
