.heading_div {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  max-height: 48px;
}
.heading {
  font-size: 20px;
  font-weight: 600;
}
.title_div {
  background-color: #f9fafb;
  padding: 1rem 0 1rem 1rem;
  white-space: noWrap;
  border-bottom: 1px solid rgb(217, 217, 217);
}
.title_content {
  font-size: 14px;
  font-weight: 600;
}
.title_content span {
  font-size: 14px;
  font-weight: 500;
}
.industries_list {
  font-size: 16px;
  font-weight: 600;
  max-width: 400px;
}
