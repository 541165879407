.radioWrapper{
    display: flex;
    column-gap:3rem;
    row-gap: 1rem;
    flex-wrap: wrap;
}

.radioItem{
    min-width: fit-content;
    border: 1px solid #DFE3E4;
    padding: 4px 8px;
    border-radius: 2px;
}

.active{
    border: 1px solid #F16434;
    background: rgba(254, 239, 235, 0.3);
}