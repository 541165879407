/* adsGraderAI.module.css */
.modalContainer {
    padding: 24px;
}

.modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dfe3e4;
    padding-bottom: 16px;
    margin-bottom: 16px;
}

.modalTitle {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.closeButton {
    cursor: pointer;
    color: #888;
    font-size: 20px;
    transition: color 0.3s ease;
}

.closeButton:hover {
    color: #333;
}

.modalContent {
    padding: 16px 0;
    line-height: 1.6;
}

.reasonText {
    font-size: 16px;
    color: #555;
    margin-bottom: 12px;
}

.modalFooter {
    text-align: right;
    padding-top: 16px;
    border-top: 1px solid #dfe3e4;
}

.improveButton:hover {
    border: 1px solid #f16434 !important;
    color: #f16434 !important;
}

.panelContent {
    padding: 10px;
}