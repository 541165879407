.button {
    width: 100%;
    border-radius: 100px;
    border: var(--counter, 1px) solid var(--Secondary-Gainsboro, #e3e7ea);
    background: var(--Primary-White, #fff);
    display: flex;
    padding: 8px 16px 8px 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.button:hover {
    background-color: #e3e7ea;
}