.bold {
  font-weight: 700;
}

.divider {
  border-bottom: 1px solid #e8ebec;
  margin: 5px 0;
}

.accountTab {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.accountSection {
  width: 100%;
  gap: 2rem;
  display: flex;
}

.basicInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
}

.name {
  display: flex;
  gap: 2rem;
}

.profilePictureBox {
  width: 200px;
  height: 220px;
  border: 1px solid #e8ebec;
  background: #ffffff;
  padding: 1rem;
}

.basicInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 400px;
}

.name {
  display: flex;
  gap: 2rem;
}

.profilePictureBox {
  position: relative;
  width: 200px;
  height: 220px;
  border: 1px solid #e8ebec;
  background: #ffffff;
  padding: 1rem;
}

.otherPreferences {
  width: 100%;
}

.radioContainer {
  display: flex;
  gap: 5rem;
  max-width: 640px;
}

.submitSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formControlButton {
  display: flex;
  gap: 2rem;
}

.saveButton {
  padding: 0 2rem !important;
}

.cancelButton {
  border: none !important;
}

.photCircle {
  width: 110px;
  height: 110px;
  border-radius: 100%;
  overflow: hidden;
  background-color: #f5f5f5;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photoActions {
  cursor: pointer;
  position: absolute;
  bottom: 23%;
  right: 26%;
}

.uploadButton {
  margin-top: 0.7rem;
  text-align: center;
}

.fileInput {
  display: none;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.moreCurrencies {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.timeZoneDropdown {
  border: 2px solid #e8ebec;
  max-width: 640px;
  padding: 5px 10px;
}

.timeZoneContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: red;
}

.changePassword {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.newPassword {
  width: 100%;
}

.confirmNewPassword {
  width: 100%;
}

.error {
  color: tomato;
}

.text {
  color: #8F8F8F;
}