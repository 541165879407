@import '@styles/_variables.scss';

.backgroundContainer {
  position: relative;
  width: 100%;
  height: 90vh; /* Makes the background full screen */
  display: flex; /* Enables flexbox */
  align-items: center; /* Centers items vertically */
  justify-content: center; /* Centers items horizontally */
  overflow: hidden; /* Prevents scrolling if the image is larger than the screen */
}

.backgroundImage {
  opacity: 0.2;
  position: absolute;
  width: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
  z-index: -1; /* Sends the image to the back */
}

.loginCard {
  background: white;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
}
