.heading {
  color: var(--Primary-Orange, #f16434);
  font-size: 22px;
  font-style: normal;
  font-weight: bold;
  line-height: 118.182%;
  /*  */
}


.clearFilter {
  cursor: pointer;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  text-decoration-line: underline;
}

.clearFilter:hover {
  color: #f16434;
  font-size: 17px;
}

.createMyAccount {
  cursor: pointer;
  color: white;
  padding: 6px;
  border: 1px solid rgba(255, 255, 255, 0.50);
  background: #4285F4;
  text-align: center;
  font-size: 11px;
  font-weight: bold;
}