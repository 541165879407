.scrollableDiv::-webkit-scrollbar {
  width: 100% !important;
  height: 100% !important;
  overflow-y: auto;
}

.searchResultCount {
  margin-top: 22px;
  color: var(--Primary-Raisin-Black, #26282c);
  line-height: 20px;
  /* 100% */
}

.date {
  color: var(--Secondary-Gray, #a3b0b3);
  line-height: 20px;
  /* 111.111% */
  letter-spacing: 1.44px;
  margin-top: 40px;
}