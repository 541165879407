@import '@styles/_variables.scss';

.pageWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 16px;
}

.chartWrapper {
  display: flex;
  width: 100%;
}

.chartLegends {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chartLegendCol {
  flex: 1;
  text-align: right;
}

.calculator {
  // display: flex;
  // flex-direction: row;
  // background-color: red;
  margin-top: 40px;
  margin-left: 20px;
  margin-right: 20px;
}
.ppcAdsSection {
}

@media (max-width: 992px) {
  .reportRightSide {
    max-width: 100%;
  }
}

.quote {
  margin-top: 140px;
}
@keyframes showCol {
  0% {
    height: 0px;
  }
  100% {
    height: 100%;
  }
}

@keyframes hiddenCol {
  0% {
    height: 100%;
  }
  100% {
    height: 0px;
  }
}

.improvementCol {
  width: 100%;
  // max-height: 286.14px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  overflow: hidden;
  gap: 16px;
  margin: 16px 0;
}

.hiddenImprovementCol {
  animation-name: hiddenCol;
  height: 0px;
  animation-duration: 1s;
}

.showImprovementCol {
  animation-name: showCol;
  // animation-duration: 1.5s;
  animation-duration: 0s;
}

@media (max-width: 992px) {
  .improvementCol {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 559px) {
  .improvementCol {
    grid-template-columns: repeat(1, 1fr);
  }
}

.btnGhostDisableStyle:disabled {
  border-color: #d9d9d9 !important;
  cursor: default;
}

.btnPrimaryDisableStyle:disabled {
  color: #fff;
  border: none;
  background: #f16434;
  cursor: default;
  opacity: 0.65;

  &:hover {
    color: #fff;
    background: #f16434;
  }
}

@media (max-width: 992px) {
  .quote {
    display: flex;
    justify-content: center;
    margin-top: 0;
    text-align: center;
  }
}

.loadingCard {
  text-align: center;
  padding: 24px;

  @media (max-width: 768px) {
    padding: 0;
  }
}

.templateTitle {
  text-align: center;
  margin: 80px 0;
}

.templateCard {
  margin-bottom: -100px;
}

.subHeader {
  line-height: 1.1;
}

.headerLink {
  margin-top: 8px;
  text-decoration: underline 2px;
  color: white;
}
.headerFooter {
  margin-bottom: 42px;
}

@media (max-width: 992px) {
  .headerLeftSide {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .headerFooter {
    margin-bottom: 0;
    .headerLink {
      margin-top: 0;
    }
  }
}

.threeStepsTitle {
  margin-top: 100px;
}

.CTACardsDiv {
  margin-top: 40px;
}

.orangeStrong {
  color: $primary-color;
}

.threeStepsFooter {
  display: flex;
  justify-content: center;
  margin: 54px 0 72px 0;
}

.teamSection {
  background: #f9f9f9;
  margin-top: 80px;
  padding: 100px 0 75px 0;
}

.socialButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.socialButton {
  margin-left: 12px;
  background: #f9f9f9;
}

.socialIconText {
  display: flex;
  align-items: center;
}

.socialIcon {
  margin-right: 10.5px;
  line-height: 1;
}

.metricsTitle {
  margin-top: 56px;
}
.tabsDiv {
  margin-top: 32px;
}

.metricsFooter {
  margin-top: 8px;
}

.infoCardDiv {
  margin-bottom: 24px;
  height: 130px;

  &:last-child {
    margin-bottom: 0;
  }
}

hr {
  border: 0;
  border-bottom: 1px solid #d1d7d9;
}

.wastedCollapseContainer {
  line-height: 2;
}

.displayHeader {
  margin-top: 64px;
}

.statsTabContainer {
  margin-top: 114px;
}

.shareContainer {
  background: #f9f9f9;
  margin-top: 178px;
  height: 607px;
}

.shareLeftSide {
  padding: 140px 0;
  height: 640px;
  width: 100%;
}

.shareLeftSideTitle {
  line-height: 1;
  max-width: 470px;
  margin-bottom: 24px;
}

.neilBannerImg {
  position: absolute;
  max-width: 100%;
  bottom: 33px;
}

.shareButtonContainer {
  margin-top: 32px;
  margin-bottom: 26px;
}

.shareConfidentialContainer {
  display: flex;
  align-items: center;
}

.shareConfidentialIcon {
  color: $primary-color;
  line-height: 1;
  margin-right: 8px;
}

.summarySection {
  background: $primary-color;
  padding: 150px 0;
}

.summaryMiddleParagraph {
  margin-top: 30px;
  max-width: 570px;
}

.summaryLastParagraph {
  margin-top: 32px;
  max-width: 570px;
}

.reportTitleContainer {
  line-height: 0.9;
}

.reportRightSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .socialButtons {
    width: 100%;
  }

  .statsTabContainer {
    margin-top: 60px;
  }

  .shareLeftSide {
    text-align: center;
  }
  .shareLeftSideTitle {
    max-width: none;
  }
  .shareConfidentialContainer {
    margin: 0 auto;
  }
  .shareRightSide {
    display: flex;
    justify-content: center;
  }
  .neilBannerImg {
    height: auto;
    margin-top: -90px;
    position: static;
  }
  .shareContainer {
    height: auto;
    margin-top: 60px;
  }
  .shareLeftSide {
    height: auto;
  }

  .summarySection {
    text-align: center;
    padding: 40px 0;
  }

  .summaryLeftSide {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reportRightSide {
    margin-top: 32px;
  }

  .metricsSection {
    text-align: center;
    padding: 8px;
  }

  .displayHeader {
    text-align: center;
  }

  .wastedSpendCard {
    width: 100%;
    display: flex;
    align-self: center;
    margin: 0 auto;
  }

  .threeStepsTitle {
    padding: 20px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .CTACardsDiv {
    margin-top: 24px;
  }

  .teamSection {
    text-align: center;
    padding: 60px 0;
  }

  .socialButton {
    margin-top: 16px;
  }

  .shareLeftSideTitle > div {
    font-size: 48px;
  }

  .shareButtonContainer {
    margin-top: 16px;
    margin-bottom: 16px;
  }

  .shareLeftSide {
    padding: 60px 0 140px 0;
  }

  .shareButton {
    font-size: 14px;
  }

  .summaryMiddleParagraph > div {
    font-size: 22px;
  }

  .summaryLastParagraph > div {
    font-size: 16px;
  }

  .reportTitleContainer {
    font-size: 64px;
  }
}
.shareReport:focus,
.shareReport:hover {
  color: #f16434;
  border-color: #ff8e61;
  background: #fff;
  border: 1px solid #f23e00 !important;
  box-shadow: 0px 0px 24px -7px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0px 0px 24px -7px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0px 0px 24px -7px rgba(0, 0, 0, 0.55);
}

.shareReport,
.shareReport {
  color: #fff;
  background: #d24913;
  transition: 0.5s ease;
  font-weight: 700;
}

.dataCard {
  padding: 12px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 17px 0px rgba(0, 0, 0, 0.22);
  background: white;
}
.extraTooltip {
  padding: 10px 12px;
  font-size: 15px;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 17px 0px rgba(0, 0, 0, 0.22);
  background: white;
}
.check2 {
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.08), 0px 5px 17px 0px rgba(0, 0, 0, 0.22);
  background: white;
  height: 130.3px;
  // height: 140.3px;
}
/* Styles for screen sizes less than or equal to 1440px */
@media (max-width: 1440px) {
  .dataCard {
    max-width: 200px;
  }
}

/* Styles for screen sizes less than or equal to 1200px */
@media (max-width: 1200px) {
  .dataCard {
    max-width: 190px;
  }
}

/* Styles for screen sizes less than or equal to 992px */
@media (max-width: 992px) {
  .dataCard {
    max-width: 130px;
  }
}

.mountedText {
  font-size: 9px;
}

/* Styles for screen sizes less than or equal to 1440px */
@media (max-width: 1440px) {
  .mountedText {
    font-size: 9px;
  }
}

/* Styles for screen sizes less than or equal to 1200px */
@media (max-width: 1200px) {
  .mountedText {
    max-width: 6px;
  }
}

/* Styles for screen sizes less than or equal to 992px */
@media (max-width: 992px) {
  .mountedText {
    max-width: 3px;
  }
}

.mountedPercentage {
  font-size: 24px;
}

/* Styles for screen sizes less than or equal to 1440px */
@media (max-width: 1440px) {
  .mountedPercentage {
    font-size: 18px;
  }
}

/* Styles for screen sizes less than or equal to 1200px */
@media (max-width: 1200px) {
  .mountedPercentage {
    max-width: 13px;
  }
}

/* Styles for screen sizes less than or equal to 992px */
@media (max-width: 992px) {
  .mountedPercentage {
    max-width: 10px;
  }
}
